/* eslint-disable */
/* eslint-disable camelcase */
import apiInstance from '../config/api/axios'

// ! gets the details of the last asset in the project
export const getLastAsset = async({getLastAsset, location_id, project_id_crm}) => {
  try {
    const queryParams = new URLSearchParams()
    if (getLastAsset) queryParams.append('getLastAsset', getLastAsset)
    if (location_id) queryParams.append('location_id', location_id)
    if (project_id_crm) queryParams.append('project_id_crm', project_id_crm)

    const {data} = await apiInstance.get(`location?${queryParams.toString()}`)
    return data
  } catch (error) {
    console.error('Error fetching last asset:', error)
    throw error
  }
}

// ! the make and capacity list
export const fetchMakeCapacityList = async({project_id_crm, type}) => {
  try {
    const {data} = await apiInstance.get(`common?project_id_crm=${project_id_crm}&type=${type}`)
    return data
  } catch (error) {
    console.error('Error fetching make capacity list:', error)
    throw error
  }
}

// ! list of all areas for a location
export const fetchAreaList = async(location_id) => {
  try {
    const {data} = await apiInstance.get(`area-by-location?location_id=${location_id}`)
    return data.areaList
  } catch (error) {
    console.error('Error fetching area list:', error)
    throw error
  }
}

// ! list of all lines for an area in a location
export const fetchLineByArea = async({area, location_id}) => {
  try {
    const {data} = await apiInstance.get(`line-by-area?area=${encodeURIComponent(area)}&location_id=${location_id}`)
    return data?.lineList
  } catch (error) {
    console.error('Error fetching line by area:', error)
    throw error
  }
}

// ! figure out
export const getAssetDetailByLocation = async({id, location_id, project_id_crm}) => {
  try {
    const queryParams = new URLSearchParams()
    if (id) queryParams.append('id', id)
    if (location_id) queryParams.append('location_id', location_id)
    if (project_id_crm) queryParams.append('project_id_crm', project_id_crm)

    const {data} = await apiInstance.get(`asset-detail?${queryParams.toString()}`)
    return data
  } catch (error) {
    console.error('Error fetching asset detail by location:', error)
    throw error
  }
}

// ! get all models
export const fetchModelListByMake = async() => {
  try {
    const response = await apiInstance.get('make-model-list')
    return response?.data
  } catch (error) {
    throw error
  }
}


// ! create/update an asset
export const postAsset = async(payload) => {
  try {
    const response = await apiInstance.post('asset-detail', payload)
    return response?.data
  } catch (error) {
    throw error
  }
}

// ! get entire asset list or assets for a location?
export const getAssetList = async({id, location_id, project_id_crm}) => {
  try {
    const {data} = await apiInstance.get(
      `asset-detail?id=${id}&location_id=${location_id}&project_id_crm=${project_id_crm}`
    )
    return data
  } catch (error) {
    throw error
  }
}

// ! details of asst currently being edited
export const getAssetInEdit = async(payload) => {
  try {
    const res = await apiInstance.post(
      'asset-list?action=getAssetInEdit',
      payload
    )
    return res?.data
  } catch (error) {
    throw error
  }
}

// ! delete asset
export const deleteCurrentAsset = async(payload) => {
  try {
    const response = await apiInstance.delete(`asset-detail/${payload}`)
    return response
  } catch (error) {
    throw error
  }
}

// ! add a new line
export const addNewLine = async(payload) => {
  try {
    const response = await apiInstance.post('line-detail', payload)
    return response
  } catch (error) {
    throw error
  }
}

// ! project data?
export const fetchProjectData = async(projectIdCrm) => {
  try {
    const response = await apiInstance.get(`project/${projectIdCrm}`)
    console.log(response?.data?.[0], 'response?.data?.[0]')
    return response?.data?.[0]
  } catch (error) {
    throw error
  }
}

export const getProjectDataByType = async (payload) => {
  try {
    const response = await apiInstance.post('asset-list', payload);
    return response?.data
  } catch (error) {
    throw error
  }
}

export const assetDrop = async(payload) => {
  try {
    const response = await apiInstance.put('asset-list/multiAssetDrop', payload)
    return response
  } catch (error) {
  }

}
export const deleteAssets = async(payload) => {
  try {
    const response = await apiInstance.delete(`asset-detail?delete_ids=${payload}`)
    return response
  } catch (error) {
    throw error
  }
}

export const blockMoveAsset = async(payload) => {
  try {
    const response = await apiInstance.put('/asset-list/moveAssets', payload)
    return response
  } catch (error) {
    throw error
  }
}

export const copyAssetBelow = async(payload) => {
  try {
    const response = await apiInstance.put('/asset-list/copyAsset', payload)
    localStorage.removeItem('alreadyScrolled')
    localStorage.setItem('type_id', response?.data?.new_type_id)
    return response
  } catch (error) {
    throw error
  }
}