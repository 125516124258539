/* eslint-disable */
import React, { useState, useEffect, useMemo } from 'react';

import { kaReducer, Table } from 'ka-table';
import {
  SortingMode,
  EditingMode,
} from 'ka-table/enums';
import { Edit, Flag as FlagIcon } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  fetchAssetList,
  fetchAssetDetails,
  getAssetList,
  postFlagged,
  updateAssetClass,
  getAssetInEdit,
} from '../../services/assetListService';
import { columns } from '../../utils/Table';
import { reorderReducer } from '../../reducers';
import DataGridStyled from './DataGridStyled.style';
import { ColumnToggle } from './Column';
import { decode as base64Decode } from 'base-64';
import {
  SelectionCell,
  PageSize,
  SelectionLine,
} from '../Tables/CustomTable/components';
import { SelectionHeader } from '../Tables/CustomTable/components';
import { kaPropsUtils } from 'ka-table/utils';
import { CustomMenu } from '../Tables/CustomTable/components';
import { useHistory } from 'react-router-dom';
import InsertAreaDialog from '../Tables/CustomTable/components/InsertAreaDialog';
import BlockMoveDialog from '../Tables/CustomTable/components/BlockMoveDialog';
import ReassignDialog from '../Tables/CustomTable/components/ReassignDialog';
import AlertMessage from '../AlertMessage';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader';
import {
  updateGroupsExpanded,
} from 'ka-table/actionCreators';
import {
  assetDropAction,
  deleteAssetAction,
  copyAssetBelowAction,
  blockMoveAssetAction,
  getAssetClassAction,
  postAssetClassAction,
  getConditions,
} from '../../redux/assets/AssetsActions';
import { assetsListActions } from '../../redux/assets/assets';
import InsertNewLineDialog from '../Tables/CustomTable/components/InsertNewLineDialog';
import { ParentMenu } from '../Tables/CustomTable/components';
import SelectionDropdown from '../SelectionDropdown/selectionDropdown';
import { Box, IconButton, Typography } from '@mui/material';
import {
  generateKey,
  convertConditionToConditionValue,
  convertConditionValueToCondition,
  convertClassValueToClass,
  storageCleanUp,
  scrollToHighlightedRow,
  extractLineAndAsset,
  transformValues,
  isDropAllowed,
} from '../../utils/Utils';
import ConditionDropdown from '../ConditionDropdown';
import EditPopup from '../Tables/CustomTable/components/EditPopup/editPopup';
import { column } from '../../utils/Column';
import { lineListActions } from '../../redux/lines/line';
import ConfirmationAlert from '../ConfirmationAlert';
import { getLocationsDataAction } from '../../redux/home/HomeActions';
import {
  getMakeCapacityAction,
  getAssetDetailsAction,
} from '../../redux/lines/LineActions';
import { getModelByMakeAction } from '../../redux/assets/AssetsActions';
import { condition } from '../../constants/constant';
import { updateAssetCount } from '../../redux/general/general';
import { assetDrop, blockMoveAsset, copyAssetBelow, deleteAssets } from '../../services/apiService';

const MODEL_DATA = localStorage.getItem('make_model');
const PAGE_SIZE = localStorage.getItem('itemsPerPage')
  ? localStorage.getItem('itemsPerPage')
  : 50;
const CURRENT_PAGE = localStorage.getItem('current_active_page')
  ? +localStorage.getItem('current_active_page')
  : null;

const DataGrid = ({
  config,
  columnConfig,
  handleSidebar,
  columnSetting,
  myProjectData,
  inputdata,
  // isGlobalSearch,
  filterAssetInDataGrid,
  // search,
  appraiserData,
  resetGlobalSearch,
  fetchAssetsInChild,
  resetFilterInDataGrid
}) => {
  console.log("🚀 ~ appraiserData:", appraiserData)
  const history = useHistory();
  const reduxDispatch = useDispatch();

  const {
    dataKeys: { attributes, orders },
  } = config;
  const {
    dataKeys: { attribute, order },
  } = columnConfig;

  // ! updated partly
  const tablePropsInit = {
    columns: orders.map((key) => {
      return {
        ...columns[key],
        visible: attributes[key].visibility,
      };
    }),
    data: [],
    height: '75vh',
    editingMode: EditingMode.Cell,
    rowKeyField: 'type_id',
    rowReordering: true,
    groups: [
      {
        columnKey: 'location_address',
        key: 'location_id',
      },
      {
        columnKey: 'area',
        key: 'area_order',
      },
      // {
      //   columnKey: 'parent_line',
      // },
    ],
    sortingMode: SortingMode.Single,
    paging: {
      enabled: true,
      // pageIndex: 0,
      pageIndex: CURRENT_PAGE ? CURRENT_PAGE : 0,
      pageSize: +PAGE_SIZE,
      pageSizes: [50, 100, 150, 200, 300],
    },
  };
  // ! updated partly
  const columnPropsInit = {
    columns: order.map((key) => {
      return {
        ...column[key],
        visible: attribute[key].visibility,
      };
    }),
  };

  // my state variables
  const [currentActiveRowId, setCurrentActiveRowId] = useState(null)
  const [dropDownType, setDropDownType] = useState(null)
  const [fetchAssets, setFetchAssets] = useState(false)
  const [messageData, setMessageData] = useState(null);
  // my state variables
  const [columnChooserProps, changeColumnChooserProps] =
    useState(tablePropsInit);
  console.log('🚀 ~ changeColumnChooserProps:', columnChooserProps);
  const [columnProps, changeColumnProps] = useState(columnPropsInit);
  const [rows, setRows] = useState([]);
  console.log('🚀 ~ rows:', rows);
  // const [lines, setLines] = useState();
  // const [fetchAssetDetailsRequest, setFetchAssetDetailsRequest] =
  //   useState(false);
  const [valueCode, setValueCode] = useState([]);
  // const [assetDetails, setAssetDetails] = useState();
  const [visible, setVisible] = useState(false);
  const [isFetchingList, setIsFetchingList] = useState(false);
  const [posX, setPosX] = useState(0);
  const [posY, setPosY] = useState(0);
  const [rowData, setRowData] = useState();
  const [parentRowData, setParentRowData] = useState();
  const [selectedEditableCell, setSelectedEditableCell] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [item, setItem] = useState();
  const [addArea, setAddArea] = useState();
  const [assetData, setAssetData] = useState([]);
  console.log('🚀 ~ assetData:', assetData);
  const [flagId, setFlagId] = useState();
  const assetsState = useSelector((state) => state.assets);
  console.log('🚀 ~ assetsState:', assetsState);
  const [open, setOpen] = useState(false);
  // const [draggedRow, setDraggedRow] = useState(null);
  const [assetResponse, setAssetResponse] = useState([]);
  // const [toggleClass, setToggleClass] = useState(false);
  // const [currentRowId, setCurrentRowId] = useState(null);
  // const [areaEdit, setAreaEdit] = useState(false);
  const [areaName, setAreaName] = useState(null);
  const [newAreaName, setNewAreaName] = useState(null);
  // const [searchValue, setSearchValue] = useState();
  const [toggleCondition, setToggleCondition] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // const [currentRowIdCondition, setCurrentRowIdCondition] = useState(null);
  const [locationValue, setLocationValue] = useState();
  const [selectItems, setSelectItems] = useState([]);
  console.log('🚀 ~ selectItems:', selectItems);
  // we need to set a loading state since we are directly calling the service, this needs to be managed somehow
  // const [getAssetListData, setGetAssetListData] = useState(false);
  // const [lastGetAssetData, setLastGetAssetData] = useState(true); // ! will remove as part of new sprint when we cleanup redux - set it back to false might cause new issues now
  // const tableRef = useRef(null); // Create a ref for Ka-Table instance
  const { doFetchAssetList } = useSelector((state) => state.assets);
  // const adminState = useSelector((state) => state.admin);
  const lineState = useSelector((state) => state.line);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  // const [isEnable, setEnable] = useState(false);
  const [value, setValue] = useState([]);
  const [inputLineValues, setInputLineValues] = useState({});
  const assetTypes = assetData?.assetTypeList;
  const makeList =
    lineState?.makeCapacityList?.makeCapacityList?.data?.makeList;
  const filteredAssetTypes =
    assetTypes?.length > 0 &&
      assetTypes[0]?.asset_type === 'Select or search asset type'
      ? assetTypes?.slice(1)
      : assetTypes;

  const VISIBLE_COLUMNS = localStorage.getItem('visibleColumns');
  // const ITEMS_PER_PAGE = localStorage.getItem('itemsPerPage');
  const filterAppraiserId = localStorage.getItem('selectedAppraiser');
  const filterArea = localStorage.getItem('selectedArea');
  const selectedLocation = localStorage.getItem('location_id_home');
  // const refPayload = {
  //   pageNumber: 3, // not needed but sending just till we update the api post prod
  //   pageSize: 60, // not needed but sending just till we update the api post prod
  //   sortCol: 'area_order',
  //   sortOrder: 'asc',
  //   action: 'getAssetInEdit',
  //   isAudio: false, // not needed but sending just till we update the api post prod
  //   NForDeleted: false, // not needed but sending just till we update the api post prod
  //   showOnlyAsset: false, // not needed but sending just till we update the api post prod
  //   hideCategorized: false, // not needed but sending just till we update the api post prod
  //   appraiser: filterAppraiserId, // not needed but sending just till we update the api post prod
  //   make: null, // not needed but sending just till we update the api post prod
  //   model: null, // not needed but sending just till we update the api post prod
  //   capacity: null, // not needed but sending just till we update the api post prod
  //   area: filterArea, // not needed but sending just till we update the api post prod
  //   global_search: null, // not needed but sending just till we update the api post prod
  //   location: selectedLocation, // not needed but sending just till we update the api post prod
  //   currentPageNumber: 3, // not needed but sending just till we update the api post prod
  // };

  // !updated
  useEffect(() => {
    let makeCapacityData = {
      type: 'getMakeCapacityList',
      project_id_crm: decodedProjectId,
    };
    reduxDispatch(getAssetClassAction());
    reduxDispatch(getConditions());
    reduxDispatch(getMakeCapacityAction(makeCapacityData));
    if (!MODEL_DATA) reduxDispatch(getModelByMakeAction());
  }, [reduxDispatch]);
  // ! updated
  useEffect(() => {
    storageCleanUp();
  }, []);
  // useEffect(() => {
  //   if (
  //     doFetchAssetList ||
  //     getAssetListData ||
  //     isGlobalSearch ||
  //     isFetchingList
  //   )
  //     setLastGetAssetData(false);
  // }, [doFetchAssetList, getAssetListData, isGlobalSearch, isFetchingList]); // - needs absolute cleanup because it is causing too many api calls
  // ! don't ever even consider mixing these two useeffects thinking you are smart. I'll kick your ass when I find you
  useEffect(() => {
    if (!VISIBLE_COLUMNS) {
      localStorage.setItem('visibleColumns', JSON.stringify(columnProps));
    }
  }, [columnProps, VISIBLE_COLUMNS]);

  useEffect(() => {
    changeColumnProps(JSON.parse(VISIBLE_COLUMNS));
  }, [VISIBLE_COLUMNS]);

  // ? - 106 // !when do we set location_id in the url?
  // const filterLocation = new URLSearchParams(location.search).get('location');

  // let typeId;
  let userId = localStorage.getItem('userId');
  let projectIDCrm = localStorage.getItem('project_id_crm');
  let decodedProjectId = base64Decode(projectIDCrm); // This is a Base64 encoded string

  // ! updated
  const generateUpdatedTableProps = (computedOrders) => {
    const PAGE_SIZE = localStorage.getItem('itemsPerPage')
      ? localStorage.getItem('itemsPerPage')
      : 50;
    const computedTablePropsInit = {
      ...tablePropsInit,
      columns: computedOrders.map((key) => {
        return {
          ...columns[key],
          visible: VISIBLE_COLUMNS
            ? JSON.parse(VISIBLE_COLUMNS)?.columns?.find((i) => i.key == key)
              ?.visible
            : attributes[key].visibility,
        };
      }),
      paging: {
        enabled: true,
        pageIndex: 0,
        pageSize: +PAGE_SIZE,
        pageSizes: [50, 100, 150, 200, 300],
      },
    };
    return computedTablePropsInit;
  };

  // ? wtf is this, who wrote this shit?
  useEffect(() => {
    // ! what does this do?
    return;
    let locationGetLastAsset = {
      getLastAsset: true,
      project_id_crm: decodedProjectId,
      flag: 'addAsset',
    };
    let makeCapacityData = {
      type: 'getMakeCapacityList',
      project_id_crm: decodedProjectId,
    };
    reduxDispatch(getLocationsDataAction(locationGetLastAsset));
    reduxDispatch(getMakeCapacityAction(makeCapacityData));
    if (
      !assetsState?.getModelByMake.loading &&
      assetsState?.getModelByMake?.getModelByMake.length === 0 &&
      !MODEL_DATA
    ) {
      reduxDispatch(getModelByMakeAction());
    }
  }, [reduxDispatch]);
  // handle with local storage location id

  // ? - 109
  useEffect(() => {
    if (columnChooserProps?.editableCells?.length) {
      // ! I know it's pretty ugly and unconventional to use DOM manipulation in react but the case just didn't work no matter what I tried with our implementation. Something was done wrong and I cannot find it in this 300+ line of bs
      const elems = document.getElementsByClassName('ka-input');
      if (!elems.length) return;
      elems[0].select();
    }
  }, [columnChooserProps?.editableCells]);

  // ? needed?
  useEffect(() => {
    return;
    let assetData = {
      location_id: inputdata?.location ?? '',
      type: 'line',
      project_id_crm: decodedProjectId,
    };
    reduxDispatch(getAssetDetailsAction(assetData));
  }, []);


  // ! updated
  useEffect(() => {
    if (columnChooserProps?.selectedRows?.length > 0) {
      setSelectItems(
        rows?.filter(({ type_id }) =>
          columnChooserProps?.selectedRows?.includes(type_id)
        )
      );
    }
  }, [columnChooserProps?.selectedRows, rows]);

  // ! updated
  // useEffect(() => {
  //   reduxDispatch(getAssetClassAction());
  //   reduxDispatch(getConditions());
  // }, [reduxDispatch]);

  // ! updated
  useEffect(() => {
    const updatedColumns = columnChooserProps?.columns.map((data) => {
      const matchingItem = columnProps?.columns.find(
        (item) => item.key === data.key
      );
      if (matchingItem) {
        return { ...data, visible: matchingItem.visible };
      }
      return data;
    });

    if (
      JSON.stringify(updatedColumns) !==
      JSON.stringify(columnChooserProps.columns)
    ) {
      changeColumnChooserProps({
        ...columnChooserProps,
        columns: updatedColumns,
      });
    }
  }, [columnProps, columnChooserProps]);
  // ! just to differentiate the performance improvements in the new implementation
  // useEffect(() => {
  //   columnProps?.columns?.map((item) => {
  //     columnChooserProps?.columns.map((data) => {
  //       if (item?.key === data.key) {
  //         data.visible = item.visible;
  //       }
  //     });
  //     changeColumnChooserProps({ ...columnChooserProps });
  //   });
  // }, [columnProps]);

  // ? - 116
  const dispatch = (action) => {
    changeColumnProps((prevState) => {
      switch (action.type) {
        case 'ReorderRows':
          return reorderReducer(prevState, action);
        case 'UpdateCellValue':
          return kaReducer(prevState, action);
        default:
          return kaReducer(prevState, action);
      }
    });
  };

  // ? - 117
  const kaTableDispatch = (action) => {
    changeColumnChooserProps((prevState) => {
      switch (action.type) {
        case 'ReorderRows':
          return reorderReducer(prevState, action);
        case 'UpdateCellValue':
          const key = generateKey(action.columnKey);
          const currentActiveRow = prevState.data.find(
            ({ type_id }) => +type_id === action.rowKeyValue
          );
          let obj = {
            key,
            key_value: action?.value,
            // key === 'year' ? action.value : CheckValue(action, prevState),
            modified_by: Number(userId),
            type: currentActiveRow.type,
            type_id: action.rowKeyValue,
            update_type: key == 'year' ? 'field' : 'value',
          };
          // ! we only proceed if the current cell is one of the price values (flv, olv, fmv, etc...)
          if (valueCode?.includes(action.columnKey)) {
            // ! if the value in empty during edit and we move to another cell or out of focus of this cell
            if (obj.key_value == 0 && currentActiveRow.type == 'line') {
              // return
              let allRows = [...prevState?.data];
              action.value = ''; // we reset the value from the action itself, thus updating the table in the process
              obj.key_value = ''; // we just won't make the api call anymore then.
              const allChildRows = allRows.filter(
                ({ parent_line }) => +parent_line === +obj.type_id
              );
              let newLinePriceValue = 0;
              allChildRows.forEach((childRow) => {
                if (parseInt(childRow[action.columnKey]) > 0) {
                  newLinePriceValue += parseInt(childRow[action.columnKey]);
                }
              });
              const currentRowFromList = allRows.find(
                ({ type_id }) => +type_id === +action.rowKeyValue
              );
              currentRowFromList[action.columnKey] = newLinePriceValue;
              action.value = newLinePriceValue;
              const valueType = `${action.columnKey}-color`;
              currentRowFromList[valueType] = '0';
              if (currentRowFromList.overriden) {
                let overriddenValues = currentRowFromList.overriden.split('#');
                let index = valueCode?.indexOf(action.columnKey);

                if (index !== -1) {
                  overriddenValues[index] = '0';
                }

                currentRowFromList.overriden = overriddenValues.join('#');
              }
            } else if (
              (obj.key_value.toString().toLowerCase() != 'no value' &&
                obj.key_value.toString().toLowerCase() != 'not valued' &&
                obj.key_value.toString().toLowerCase() != 'leased' &&
                isNaN(obj.key_value)) ||
              !obj.key_value
            ) {
              action.value = ''; // we reset the value from the action itself, thus updating the table in the process
              obj.key_value = ''; // we just won't make the api call anymore then.
              let allRows = [...prevState?.data];
              if (currentActiveRow.type == 'line') {
                // ! if current row is a line, we first reset it's value to a sum of all the assets in that line and set the overriden
                //! field to 0 for that price value. eg: 0#0#0 is olv#flv#fmv, we set whichever one we updated to 0 so the color is set to black
                const allChildRows = allRows.filter(
                  ({ parent_line }) => +parent_line === +obj.type_id
                );
                let newLinePriceValue = 0;
                allChildRows.forEach((childRow) => {
                  if (parseInt(childRow[action.columnKey]) > 0) {
                    newLinePriceValue += parseInt(childRow[action.columnKey]);
                  }
                });
                const currentRowFromList = allRows.find(
                  ({ type_id }) => +type_id === +action.rowKeyValue
                );
                currentRowFromList[action.columnKey] = newLinePriceValue;
                action.value = newLinePriceValue;
                const valueType = `${action.columnKey}-color`;
                currentRowFromList[valueType] = '0';
                if (currentRowFromList.overriden) {
                  let overriddenValues =
                    currentRowFromList.overriden.split('#');
                  let index = valueCode?.indexOf(action.columnKey);

                  if (index !== -1) {
                    overriddenValues[index] = '0';
                  }

                  currentRowFromList.overriden = overriddenValues.join('#');
                }
              }
              if (currentActiveRow.type == 'asset') {
                // ! if the row type is asset, we remove it's value and set it to an empty string or null in step 1
                allRows.find(({ type_id }) => +type_id === +action.rowKeyValue)[
                  action.columnKey
                ] = null; // ! we no longer set a value because we reset it if the value is not valid and in case of an asset that means no value at all
                // ! if the current price value we are updating has not be overriden in the line of this asset, we update the line price value for that key.
                if (
                  +currentActiveRow.parent_line > 0 &&
                  allRows
                    .find(
                      ({ type_id }) =>
                        +type_id === +currentActiveRow.parent_line
                    )
                    .overriden?.split('#')[
                  valueCode?.indexOf(action.columnKey)
                  ] != '1'
                ) {
                  const allChildRows = allRows.filter(
                    ({ parent_line }) =>
                      +parent_line === +currentActiveRow.parent_line
                  );
                  let newLinePriceValue = 0;
                  allChildRows?.forEach((childRow) => {
                    if (parseInt(childRow[action.columnKey]) > 0) {
                      newLinePriceValue += parseInt(childRow[action.columnKey]);
                    }
                  });
                  allRows.find(
                    ({ type_id }) => +type_id === +currentActiveRow.parent_line
                  )[action.columnKey] = newLinePriceValue;
                }
              }
              setRows(allRows);
            } else {
              if (action.value == 0) action.value = '';
              let allRows = [...prevState?.data];
              const currentRows = [...prevState?.data]; //! just a placeholder in case things go wrong and we have to revert
              if (currentActiveRow.type == 'line') {
                // ! if we are updating a line price value we update it's value and set overriden to 1 for that price key and the color to 1 as well.
                let currentRowFromList = allRows.find(
                  ({ type_id }) =>
                    Number(type_id) === Number(action.rowKeyValue)
                );
                if (currentRowFromList) {
                  currentRowFromList[action.columnKey] = action.value;
                  if (currentRowFromList.overriden) {
                    let overriddenValues =
                      currentRowFromList.overriden.split('#');
                    let index = valueCode?.indexOf(action.columnKey);
                    if (index !== -1) {
                      overriddenValues[index] = '1';
                      const valueType = `${action.columnKey}-color`;
                      currentRowFromList[valueType] = '1';
                      // if (obj.key_value == 0) {
                      //   currentRowFromList[valueType] = '0'
                      // } else {
                      //   currentRowFromList[valueType] = '1'
                      // }
                    }
                    currentRowFromList.overriden = overriddenValues.join('#');
                  }
                  allRows = allRows.map((row) =>
                    row.type_id === currentRowFromList.type_id
                      ? currentRowFromList
                      : row
                  );
                }
              }
              if (currentActiveRow.type == 'asset') {
                allRows.find(({ type_id }) => +type_id === +action.rowKeyValue)[
                  action.columnKey
                ] = action.value;
                //! if the asset has a parent line and the line value has not been set manually we update it.
                if (
                  +currentActiveRow.parent_line > 0 &&
                  allRows
                    .find(
                      ({ type_id }) =>
                        +type_id === +currentActiveRow.parent_line
                    )
                    .overriden?.split('#')[
                  valueCode?.indexOf(action.columnKey)
                  ] != '1'
                ) {
                  const allChildRows = allRows.filter(
                    ({ parent_line }) =>
                      +parent_line === +currentActiveRow.parent_line
                  );
                  let newLinePriceValue = 0;
                  allChildRows?.forEach((childRow) => {
                    if (parseInt(childRow[action.columnKey]) > 0) {
                      newLinePriceValue += parseInt(childRow[action.columnKey]);
                    }
                  });
                  allRows.find(
                    ({ type_id }) => +type_id === +currentActiveRow.parent_line
                  )[action.columnKey] = newLinePriceValue;
                }
              }
              setRows(allRows);
            }
            updateAssetClass(obj)
              .then()
              .catch(() => {
                setRows(currentRows);
              });
          } else if (key === 'year') {
            let allRows = [...rows];
            for (const row of allRows) {
              if (row.type_id === currentActiveRow.type_id) {
                row.year = obj.key_value;
                break
              }
            }
            let currentRows = [...rows];
            const currentColumnState = { ...columnChooserProps };
            setRows(allRows);
            changeColumnChooserProps({ ...columnChooserProps, data: allRows });
            updateAssetClass(obj)
              .then(() => { })
              .catch(() => {
                setMessageData({ message: 'Something wen\'t wrong', status: 'warning' })
                setRows(currentRows);
                changeColumnChooserProps(currentColumnState);
              });
          } else {
            // ? - will remove this altogether because of the absurd complexity it causes
            reduxDispatch(
              postAssetClassAction(obj, key == 'year' ? 'year' : 'values')
            );
          }
          return kaReducer(prevState, action);
        default:
          return kaReducer(prevState, action);
      }
    });
  };
  // ! updated
  // const cellSkeleton = (content, args, index) => {
  //   return (
  //     <td key={index} className="ka-group-cell" {...args}>
  //       <div className="ka-group-cell-content table_inner_text">
  //         <div className="ka-group-text">{content}</div>
  //       </div>
  //     </td>
  //   );
  // };
  // ! updated partly
  const generateCell = (key, content, args, index) => {
    if (key === 'quantity') {
      content = 'quantityType';
    }
    return (<td key={index} className="ka-group-cell" {...args}>
      <div className="ka-group-cell-content table_inner_text">
        <div className="ka-group-text">{content}</div>
      </div>
    </td>)
  };

  // ! new data row line function
  const newDataRowLine = (lines, rows, currentLine) => {
    if (!lines[currentLine]) return;

    const { value_short_name, value, overriden } = lines[currentLine][0];
    const valueArray = value.split('#');
    const values = value_short_name.split('#').reduce((acc, val, i) => {
      acc[val] = valueArray[i] !== '' ? valueArray[i] : '';
      return acc;
    }, {});
    const updatedArray = overriden.split('#');
    const overridenValues = value_short_name
      .split('#')
      .reduce((acc, val, index) => {
        acc[`${val}-color`] = updatedArray[index];
        return acc;
      }, {});
    const lineAssets = rows?.filter(
      (row) => row.parent_line === parseInt(currentLine)
    );
    const lineValues = lineAssets.reduce((acc, row) => {
      valueCode?.forEach((locationPriceValue) => {
        acc[locationPriceValue] =
          (acc[locationPriceValue] || 0) +
          (isNaN(row[locationPriceValue]) ||
            !Number.isInteger(row[locationPriceValue])
            ? 0
            : row[locationPriceValue]);
      });
      return acc;
    }, {});

    const currentLineDetails = {
      ...lines[currentLine][0],
      ...lineValues,
      ...values,
      ...overridenValues,
    };

    return currentLineDetails;
  };
  // todo - 121
  const generateObject = (attributes, currentLineDetails, valueCode) => {
    const generatedObject = {};

    orders.forEach((column) => {
      if (attributes[column].visibility) {
        if (attributes[column].model?.includes('quantity')) {
          generatedObject[column] =
            currentLineDetails[attributes[column].secondaryKey];
        } else if (attributes[column].model?.includes('line')) {
          generatedObject[column] =
            currentLineDetails[column] ||
            currentLineDetails[attributes[column].secondaryKey];
        } else if (attributes[column].toggable) {
          generatedObject[column] = null; // Set to null for toggables (customize as needed)
        }
      }
    });

    return generatedObject;
  };

  // ? - 122
  const DataRowLine = (props) => {
    const { groupKey, groupIndex, lines, rows, attributes, valueCode, orders } =
      props;

    if (
      groupKey[groupIndex] === '0' ||
      !lines ||
      !lines[groupKey[groupIndex]]
    ) {
      return null;
    }

    const currentLine = groupKey[groupIndex];
    const currentLineDetail = lines[currentLine][0];
    const { value_short_name, value } = currentLineDetail;
    const valueArray = value.split('#');
    const values = value_short_name.split('#').reduce((acc, val, i) => {
      acc[val] = valueArray[i] !== '' ? valueArray[i] : '';
      return acc;
    }, {});

    const lineAssets = rows?.filter(
      (row) => row.parent_line === parseInt(currentLine)
    );
    const lineValues = lineAssets.reduce((acc, row) => {
      valueCode?.forEach((locationPriceValue) => {
        acc[locationPriceValue] =
          isNaN(row[locationPriceValue]) ||
            !Number.isInteger(row[locationPriceValue])
            ? 0
            : acc[locationPriceValue] + row[locationPriceValue];
      });
      return acc;
    }, {});

    const currentLineDetails = {
      ...currentLineDetail,
      ...lineValues,
      ...values,
    };

    const generatedObject = generateObject(
      attributes,
      currentLineDetails,
      valueCode
    );

    return (
      <>
        <td className="ka-empty-cell" />
        <td className="ka-empty-cell" />
        <td className="ka-empty-cell" />
        <td className="ka-group-cell">
          <div className="ka-group-cell-content">
            <div className="ka-group-text">{currentLine}</div>
          </div>
        </td>
        {orders.map((column, index) => {
          if (
            attributes[column].visibility &&
            attributes[column].model?.includes('quantity')
          ) {
            return generateCell(
              column,
              currentLineDetails[attributes[column].secondaryKey],
              {
                title: column,
                ...(valueCode?.includes(column) &&
                  values[column] !== '' && { style: { color: 'red' } }),
              },
              index
            );
          } else if (
            attributes[column].visibility &&
            attributes[column].model?.includes('line')
          ) {
            return generateCell(
              column,
              currentLineDetails[column] ||
              currentLineDetails[attributes[column].secondaryKey],
              {
                title: column,
                ...(valueCode?.includes(column) &&
                  values[column] !== '' && { style: { color: 'red' } }),
              },
              index
            );
          } else if (
            attributes[column].visibility &&
            attributes[column].toggable
          ) {
            return <td key={index} className="ka-empty-cell" />;
          }
        })}
      </>
    );
  };

  //! updated partially
  // const handleResetSearch = () => {
  //   updateParentGlobalSearch(false);
  // };

  // for remove two rows highlighted at the same time

  const updateLocationOrder = (assets) => {
    const newAssetData = [];
    // ! we now set a new field in the assets called locationOrder which we take from the locationData based on the indexof the location.
    //!  We then use this locationOrder field to order the assets rather than the location_id thus adding right ref to each asset/line
    assets.forEach((item, index) => {
      newAssetData[index] = {
        ...item,
        locationOrder: myProjectData?.locations.findIndex(
          ({ location_id }) => +location_id === +item?.location_id
        ),
      };
    });
    return newAssetData;
  };
  // ? - 126 fetch asset list api
  useEffect(() => {
    const fetchData = async () => {
      const locationIdFromLs = localStorage.getItem('location_id_home');
      const selectedArea = localStorage.getItem('selectedArea');
      const selectedAppraiser = localStorage.getItem('selectedAppraiser');
      const searchString = localStorage.getItem('global_search_string');
      let globalAssets;
      try {
        if (
          myProjectData &&
          myProjectData.locations
        ) {
          setIsLoading(true);
          // ! updated
          let res, locationPriceData
          if (valueCode.length) {
            res = await fetchAssetList({
              ...inputdata,
              location: locationIdFromLs,
              area: selectedArea,
              appraiser: selectedAppraiser,
              search: searchString,
            })

          } else {
          [res, locationPriceData] = await Promise.all([
            fetchAssetList({
              ...inputdata,
              location: locationIdFromLs,
              area: selectedArea,
              appraiser: selectedAppraiser,
              search: searchString,
            }),
            fetchAssetDetails({
              project_id_crm: decodedProjectId,
              type: 'line',
            })
          ])
          }

          let valueCodeRes;
          if (!valueCode.length) {
            valueCodeRes = locationPriceData?.locationPriceValues?.reduce(
              (acc, curr) => [...acc, curr.value_short_name],
              []
            );
            setValueCode(valueCodeRes);
          }
          setAssetResponse(res);
          reduxDispatch(updateAssetCount(res?.totalAssetCount)); // ! we manage the asset count with redux now
          if(!res.asset.length) {
            changeColumnChooserProps({...columnChooserProps, data: res.asset})
          }

          let { line, asset, min_ref_no: startingRefIndex } = res;
          // let { line: linesFromApi, asset: assetsFromApi, min_ref_no: startingRefIndex } = res;
          // ? sceptical as to why this one doesn't get triggered anymore
          if (!line && typeof asset === 'object' && !Array.isArray(asset)) {
            // ! not even sure when this gets triggered anymore.
            // this is a search thing, no energy to explain rn
            let { assetList, lineList } = extractLineAndAsset(asset);
            asset = assetList;
          }
          asset = updateLocationOrder(asset); // ! we add a location order field which we then use to sort the assets
          // ! updated
          asset.sort(
            (a, b) =>
              a.locationOrder - b.locationOrder ||
              a.area_order - b.area_order ||
              a.asset_area_order - b.asset_area_order
          );
          // ! updated
          let refAssets = asset.map((asset, index) => {
            if (
              line &&
              asset.parent_line &&
              line?.[asset.parent_line]
            ) {
              const parentLine = line[asset.parent_line][0];

              if (!parentLine.area || !parentLine.area_order) {
                Object.assign(parentLine, {
                  area_order: asset.area_order,
                  location_id: asset.location_id,
                  area: asset.area,
                  serial_number: asset.serial_number,
                  project_id: asset.project_id,
                  location_address: asset.location_address,
                  condition_value: convertConditionToConditionValue(parentLine.condition),
                  capacity: parentLine.line_name,
                });
              }
            } else if (asset.type === 'line') {
              asset.capacity = asset.line_name;
            }
            const transformedValues =
              asset.value_short_name && asset.value
                ? transformValues(asset.value_short_name, asset.value)
                : {};

            return { ...asset, ...transformedValues };
          });

          // ? don't remember this one
          const flag = {};
          refAssets = refAssets.flatMap((asset) => {
            const currentXValue = asset.parent_line;

            if (currentXValue > 0 && !flag[currentXValue] && line) {
              flag[currentXValue] = true;
              return [newDataRowLine(line, refAssets, currentXValue), asset];
            }

            return asset;
          });

          // ! we set reference numbers only if the assets are fetched without any filters except for location
          if (
            inputdata.appraiser ||
            inputdata.area ||
            searchString ||
            inputdata.hideCategorized ||
            inputdata.showOnlyAsset ||
            inputdata.NForDeleted
          ) {
            // ! not sure if we will ever do anything here
          } else {
            if (startingRefIndex) {
              let refCounter = startingRefIndex;
              refAssets = refAssets.map((item) =>
                item.parent_line === 0 ? { ...item, ref: refCounter++ } : item
              );
            }
          }

          let filteredRefAssets = refAssets;
          // ? check here
          if (inputdata.location && inputdata.location > 0) {
            filteredRefAssets = refAssets.filter(
              (item) => +item.location_id === +inputdata.location
            );
          }
          let myOrders;
          if (valueCode.length) { // ! cannot trust react to have updated the state yet
            myOrders = [...orders, ...order, ...valueCode];
          } else {
            myOrders = [...orders, ...order, ...valueCodeRes];
          }
          if (inputdata.show_assets) {
            filteredRefAssets = filteredRefAssets.filter(
              ({ type }) => type != 'line'
            );
          }
          const myTableProps = generateUpdatedTableProps(myOrders); // const myTableProps = generateUpdatedTableProps(orders);
          const CURRENT_PAGE = localStorage.getItem('current_active_page')
            ? +localStorage.getItem('current_active_page')
            : null;
          CURRENT_PAGE
            ? (myTableProps.paging.pageIndex = CURRENT_PAGE)
            : null;
          changeColumnChooserProps({
            ...myTableProps,
            data: filteredRefAssets,
          });
          globalAssets = filteredRefAssets // ! only because of the state update delay causing an issue in finally
          setRows(filteredRefAssets);
          setIsFetchingList(false);
          setIsLoading(false);
          setFetchAssets(false)
          resetGlobalSearch()
        }
      } catch (error) {
        setIsFetchingList(false);
        setIsLoading(false);
        setFetchAssets(false)
        resetGlobalSearch()
        setMessageData({ message: 'Failed to fetch assets', status: 'warning' })
      } finally {
        if (
          selectedArea ||
          inputdata.search ||
          selectedAppraiser ||
          inputdata.hide_assets ||
          inputdata.delete ||
          inputdata.show_assets
        ) {
          const updatedTableConfig = { ...columnChooserProps, data: globalAssets };
          updatedTableConfig.columns[2].visible = updatedTableConfig.columns[2].visible ? false : true
          changeColumnChooserProps(updatedTableConfig); // issue is here
        }
      }
      if (selectedEditableCell?.hasOwnProperty('columnKey')) {
        changeColumnChooserProps({
          ...columnChooserProps,
          editableCells: [selectedEditableCell],
        });
      }
    };

    fetchData();
  }, [
    myProjectData,
    // isGlobalSearch,
    fetchAssets,
    fetchAssetsInChild,
    // inputdata,
  ]);
  // ! partly updated - no longer needed since we use only type_id from localstorage now
  // useEffect(() => {
  //   let typeId = localStorage.getItem('type_id');
  //   let currentTypeRef = localStorage.getItem('current_type_ref');
  //   let typeIdForEdit = localStorage.getItem('typeIdForEdit');

  //   if (typeId) {
  //     localStorage.removeItem('current_type_ref');
  //     localStorage.removeItem('typeIdForEdit');
  //   } else if (currentTypeRef) {
  //     localStorage.removeItem('type_id');
  //     localStorage.removeItem('typeIdForEdit');
  //   } else if (typeIdForEdit) {
  //     localStorage.removeItem('current_type_ref');
  //     localStorage.removeItem('type_id');
  //   }
  // }, [doFetchAssetList, isGlobalSearch]); //had an unnecessary dependency

  // ! updated partly
  // useEffect(() => {
  //   if (assetsState?.postRenameArea?.postRenameArea?.status === 200) {
  //     setAreaEdit(false);
  //     setNewAreaName(null);
  //   }
  // }, [assetsState?.postRenameArea?.postRenameArea]);

  // ! updated partly
  useEffect(() => {
    // if(!filterAssetInDataGrid) return
    const alreadyScrolled = +localStorage.getItem('alreadyScrolled');
    const testTableProps = { ...columnChooserProps };
    delete testTableProps.paging;
    const finalTableRowData = kaPropsUtils.getData(testTableProps);
    const getTypeId = () => {
      if (inputdata?.asset_id) {
        return columnChooserProps?.data?.find(
          ({ asset_id }) => +asset_id === +inputdata?.asset_id
        )?.type_id;
      }
      if (inputdata?.ref) {
        return columnChooserProps?.data?.find(({ ref }) => +ref === +inputdata?.ref)
          ?.type_id;
      }
      if (localStorage.getItem('type_id')) {
        return localStorage.getItem('type_id');
      }
      return null;
    };

    const typeId = getTypeId();

    if (
      inputdata?.asset_id &&
      !columnChooserProps?.data?.find(
        ({ asset_id }) => +asset_id === +inputdata?.asset_id
      )?.type_id
    ) {
      setMessageData({ message: 'Please enter valid Asset Id.', status: 'warning' })
    }

    if (
      inputdata?.ref &&
      !columnChooserProps?.data?.find(({ ref }) => +ref === +inputdata?.ref)?.type_id
    ) {
      setMessageData({ message: 'Please enter valid Ref#.', status: 'warning' })
    }

    if (
      typeId &&
      (!alreadyScrolled || inputdata?.asset_id || inputdata?.ref) &&
      columnChooserProps?.data?.length
    ) {
      const timeoutId = setTimeout(() => {
        const currentPageByRowIndex = scrollToHighlightedRow(
          typeId,
          finalTableRowData
        );
        if (
          columnChooserProps.paging.pageIndex !== currentPageByRowIndex &&
          !alreadyScrolled
        ) {
          changeColumnChooserProps({
            ...columnChooserProps,
            paging: {
              ...columnChooserProps.paging,
              pageIndex: currentPageByRowIndex,
            },
          });
        }

        localStorage.setItem('alreadyScrolled', 1);
        resetFilterInDataGrid()
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [columnChooserProps, changeColumnChooserProps, filterAssetInDataGrid]);

  // ! updated partly
    const rowElementAttributes = (props) => {
    const { rowData } = props;
    let typeId; // ! if we are searching for an asset and we find it then we clear out the rest of the localStorage fields that refer to the current asset
    if (inputdata?.asset_id) {
      const foundAsset = rows.find(({ asset_id }) => asset_id == inputdata?.asset_id);
      if (foundAsset && foundAsset.type_id) {
        localStorage.setItem('alreadyScrolled', 0); // ! only in these two cases because we now need to manually reset it
        typeId = foundAsset.type_id;
        localStorage.setItem('type_id', typeId);
      }
    } else if (inputdata?.ref) {
      const foundRef = rows.find(({ ref }) => +ref == +inputdata?.ref);
      if (foundRef && foundRef.type_id) {
        localStorage.setItem('alreadyScrolled', 0); // ! only in these two cases because we now need to manually reset it
        typeId = foundRef.type_id;
        localStorage.setItem('type_id', typeId);
      }
    } else if (localStorage.getItem('type_id')) {
      typeId = localStorage.getItem('type_id');
    }

    if (typeId && +typeId === +rowData?.type_id) {
      // localStorage.removeItem('alreadyScrolled')
      return {
        id: 'highlighted_row',
        className: `highlighted-row ${rowData?.parent_line > 0 ? 'group-line-child-row' : null
          }`,
      };
    }
    const name = parseInt(inputdata?.ref) === parseInt(rowData.ref);
    const nameA = parseInt(inputdata?.asset_id) === parseInt(rowData.asset_id);

    const className =
      name || nameA
        ? 'highlighted-row'
        : `${rowData?.parent_line > 0 ? 'group-line-child-row' : ''}`;

    // const currentReferenceItem =name ?? nameA ? {blockRef} : ''

    if (nameA || name) {
      localStorage.removeItem('type_id');
      return {
        className: `${className} highlighted-row`,
        // ref: currentReferenceItem,
      };
    }
    return {
      className,
    };
  };


  // ? - 131 - merge with 133
  const handleToggleDropdown = (id, type) => {
    setCurrentActiveRowId(rows?.find(({ type_id }) => type_id === id))
    setDropDownType(type)
  }

  // ! updated
  // in every case type set 'asset' if the type is line
  const handleClassChange = async (val, props) => {
    let postAssetClassData = {
      key: 'asset_class',
      key_value: val,
      modified_by: Number(userId),
      type: props?.rowData.type,
      type_id: props?.rowKeyValue,
      update_type: 'field',
    };
    let allRows = [...rows];
    for (const row of allRows) {
      if (row.type_id === props.rowKeyValue) {
        row.asset_class_code = val;
        break;
      }
    }

    // reduxDispatch(postAssetClassAction(postAssetClassData, 'class'));
    let currentRows = [...rows];
    const currentColumnState = { ...columnChooserProps };
    try {
      setRows(allRows);
      changeColumnChooserProps({ ...columnChooserProps, data: allRows });
      const res = await updateAssetClass(postAssetClassData);
      console.log(res);
    } catch (error) {
      setMessageData({
        message: 'Something wen\'t wrong',
        status: 'warning',
      })
      setRows(currentRows);
      changeColumnChooserProps(currentColumnState);
    }

    // setToggleClass(false);
    setDropDownType(null)
    const filteredRefAssets = columnChooserProps.data;
    const rowIndex = filteredRefAssets.findIndex(
      ({ type_id }) => type_id === props.rowKeyValue
    );
    if (rowIndex !== -1) {
      filteredRefAssets[rowIndex].asset_class_code = val;
    }

    changeColumnChooserProps({
      ...columnChooserProps,
      data: filteredRefAssets,
    });
  };

  // ! updated
  const handleConditionChange = async (val, props) => {
    const key_value = assetsState?.getConditions?.getConditions?.find(
      (item) => item.value === val
    )?.id;
    let postConditionData = {
      key: 'condition',
      key_value,
      modified_by: Number(userId),
      type: props.rowData.type,
      type_id: props.rowKeyValue,
      update_type: 'field',
    };
    let allRows = [...rows];
    for (const row of allRows) {
      if (row.type_id === props.rowKeyValue) {
        row.condition_value = val;
        break;
      }
    }
    let currentRows = [...rows];
    const currentColumnState = { ...columnChooserProps };
    try {
      setRows(allRows);
      changeColumnChooserProps({ ...columnChooserProps, data: allRows });
      const res = await updateAssetClass(postConditionData);
    } catch (error) {
      setMessageData({ message: 'Something wen\'t wrong', status: 'warning' })
      setRows(currentRows);
      changeColumnChooserProps(currentColumnState);
    }
    // reduxDispatch(postAssetClassAction(postConditionData, 'condition'));
    // setToggleCondition(false);
    setDropDownType(null)
  };

  // ! updated
  const handleCheck = (rowId, flagId) => async () => {
    try {
      const isFlagged = flagId === 1 ? 0 : 1;
      const data = {
        modified_by: Number(userId),
        type_id: rowId,
        flagged: isFlagged,
      };
      let allRows = [...columnChooserProps?.data];
      for (const row of allRows) {
        if (row.type_id === rowId) {
          row.flagged = isFlagged;
          break;
        }
      }
      setRows(allRows);
      await postFlagged(data); //! we no longer wait for the flag to update but rather update the table and if something does go wrong we handle it in the catch block
    } catch (error) {
      const isFlagged = flagId === 1 ? 1 : 0; //! we revert the action if the api fails, so if we flagged the asset we un-flag it and vice-versa
      let allRows = [...rows];
      for (const row of allRows) {
        if (row.type_id === rowId) {
          row.flagged = isFlagged;
          break;
        }
      }
      setRows(allRows);
    }
  };

  // todo - 136 - later please
  const handleEditArea = (name) => {
    setNewAreaName('');
    setAreaName(name[1]);
    if (Array.isArray(name)) {
      console.log(Array.isArray(name), 'nameeee')
      for (const { area, location_address, location_id } of columnChooserProps.data || []) {
        console.count()
        if (area === name[1] && location_address === name[0]) {
          setLocationValue(location_id);
          break;
        }
      }
    }
  };

  // todo - 137 - later please
  const handleChangeAreaName = (e) => {
    let value = e.target.value;
    let flag = value.trim().toLowerCase() === areaName.toLowerCase();
    if (flag) {
      // setEnable(true);
      if (value?.length <= 250) {
        setNewAreaName(value);
      }
    } else {
      // setEnable(false);
      if (value?.length <= 250) {
        setNewAreaName(value);
      }
    }
  };

  const checkType = (value) => {
    let inputValue;
    let digitRegex = /^[0-9]+$/;
    let valid = digitRegex.test(value);
    if (valid) {
      inputValue = value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      inputValue = value;
    }
    return inputValue;
  };

  // todo - 139 - later please
  const handleUpdateArea = () => {
    let data = {
      action: 'changeAreaName',
      editAreaLocId: locationValue,
      editAreaName: areaName,
      new_name: newAreaName,
    };
    getAssetList(data).then((res) => {
      if (res === undefined) {
        setMessageData({ message: 'Something Went Wrong', status: 'warning' })
      } else {
        setMessageData({ message: 'Area Renamed Successfully!', status: 'warning' })
        setFetchAssets(true)
        // setAreaEdit(false);
        setAreaName('');
        setNewAreaName('');
      }
    });
  };

  // ! try not to mess unless necessary and you know what you are doing
  const childComponents = {
    cell: {
      elementAttributes: (props) => ({
        className:
          props.rowData?.type === 'line'
            ? props.rowData?.ref === inputdata?.ref ||
              props.rowData.asset_id === inputdata?.asset_id
              ? 'highlighted-row'
              : 'red'
            : '',
        onDrop: (e, extendedEvent) => {
          //fetching the type id of dragded row
          const draggableKeyValue = JSON.parse(
            e.dataTransfer.getData('ka-draggableKeyValue')
          );
          const dragRow = [];
          const dragLine = [];
          let { type } =
            columnChooserProps?.data?.find(
              (item) => +item?.type_id === +draggableKeyValue
            ) || {};
          // let flag= false
          let flag =
            columnChooserProps?.selectedRows?.includes(draggableKeyValue);
          if (columnChooserProps?.selectedRows?.length > 1) {
            if (flag) {
              columnChooserProps?.selectedRows?.map((item) => {
                columnChooserProps?.data?.map((d) => {
                  if (d?.type_id === item) {
                    let obj = {
                      areaFrom: d?.area,
                      // dragFromAreaOrder: d?.area_order,
                      dragFrom: d?.area_asset_order,
                      locationIdFrom: d.location_id,
                      parent_line: d?.parent_line,
                      type_id: d.type_id,
                    };
                    if (type === 'asset') {
                      obj.dragFromAreaOrder = d?.area_order;
                    }
                    dragRow.push(obj);
                  }
                });
              });
            } else {
              let dragRowData = columnChooserProps?.data?.find(
                ({ type_id }) => type_id === draggableKeyValue
              );
              let obj = {
                areaFrom: dragRowData?.area,
                // dragFromAreaOrder: dragRowData?.area_order,
                dragFrom: dragRowData?.area_asset_order,
                locationIdFrom: dragRowData.location_id,
                parent_line: dragRowData?.parent_line,
                type_id: dragRowData?.type_id,
              };
              if (type === 'asset') {
                obj.dragFromAreaOrder = d?.area_order;
              }

              dragRow.push(obj);
            }

            // dragRow.sort(
            //   (a, b) =>
            //     a.area_asset_order - b.area_asset_order
            // );
            // const compare = (a, b) => {
            //   // Choose the property for comparison (dragFrom or area_asset_order)
            //   const property = 'dragFrom'; // or 'area_asset_order'
            //   return a[property] - b[property];
            // };

            // // // Sort the dragRow array based on the chosen property
            // dragRow.sort(compare);
          } else {
            columnChooserProps?.data?.map((d) => {
              if (d.type_id === draggableKeyValue) {
                let obj = {
                  areaFrom: d?.area,
                  dragFrom: d?.area_asset_order,
                  locationIdFrom: d.location_id,
                  parent_line: d?.parent_line,
                  type_id: d.type_id,
                };
                if (type === 'asset') {
                  obj.dragFromAreaOrder = d?.area_order;
                }

                dragRow.push(obj);
              }
            });
          }
          const indexOfExtendRow = columnChooserProps?.data?.findIndex(
            (item) =>
              +item?.type_id === +extendedEvent?.childProps?.rowData?.type_id
          );
          const indexOfDraggableRow = columnChooserProps?.data?.findIndex(
            (item) => +item?.type_id === +draggableKeyValue
          );
          const movement =
            indexOfDraggableRow < indexOfExtendRow ? 'below' : 'above';

          const dropData = {
            areaOrderTo: extendedEvent?.childProps?.rowData?.area_order,
            areaTo: extendedEvent?.childProps?.rowData?.area,
            dragFromArray: dragRow.sort((a, b) => a.dragFrom - b.dragFrom),
            draggedLine: type == 'line' ? draggableKeyValue?.toString() : '',
            dropTo: extendedEvent?.childProps?.rowData?.area_asset_order,
            lineId: Number(extendedEvent?.childProps?.rowData?.parent_line),
            locationIdTo: extendedEvent?.childProps?.rowData?.location_id,
            movement: movement, // add this because of reordering of assets when drop
            projectIdCrm: decodedProjectId,
            typeIdTo: extendedEvent?.childProps?.rowData?.type_id,
          };
          e.target.classList.add('drop-handle');
          extendedEvent.dispatch('rowDroped', { extendedEvent });
          const isAllowed = isDropAllowed(
            dropData,
            extendedEvent?.childProps?.rowData?.area_asset_order
          );
          let dragFromSame = dropData?.dragFromArray[0].dragFrom;
          let moveOnSameAssetAndLine =
            extendedEvent?.childProps?.rowData?.area_asset_order;
          localStorage.setItem('type_id', dropData.dragFromArray[0]?.type_id); // add for row color
          if (extendedEvent?.childProps?.rowData?.type === 'line') {
            setMessageData({ message: 'We can not move asset over Line', status: 'warning' })
            // setLastGetAssetData(true);
          } else {
            if (isAllowed) {
              // dropData.dragFromArray.reverse();
              localStorage.setItem(
                'current_active_page',
                columnChooserProps.paging.pageIndex
              );
              // reduxDispatch(assetDropAction(dropData, type));
              setIsLoading(true)
              assetDrop(dropData).then(() => {
                alert('move?')
                setMessageData({message: 'Assets Move Successfully.', status: 'success'})
                setFetchAssets(true)
              })
            } else {
              if (dragFromSame !== moveOnSameAssetAndLine) {
                // no msg and no api hit we move the asset and line to the same asset and line
                localStorage.setItem(
                  'type_id',
                  dropData.dragFromArray[0]?.type_id
                );
                setMessageData({ message: 'We can not move asset in between the "From" and "Till" range', status: 'warning' })
                reduxDispatch(assetsListActions.setDoFetchAssetList(true)); // discuss with faraz // ! not even sure when this is triggered
                // setGetAssetListData(true);
              } else {
                // setGetAssetListData(false);
              }
            }
            // setDraggedRow(draggableKeyValue);
          }
        },
        onContextMenu: (event, cellProps) => {
          event.preventDefault(false);
          if (event.button === 2) {
            setRowData(cellProps.childProps.rowData);
            setFlagId(cellProps.childProps.rowKeyValue);
            setParentRowData(cellProps?.childProps?.rowData);
            if (props?.column?.key !== 'add-icon') {
              setPosX(event.clientX);
              setPosY(event.clientY - 160);
              if (event.clientX > 0 || event.clientY > 0) {
                if (cellProps?.childProps?.rowData?.type === 'line') {
                  setOpen(true);
                } else {
                  event.preventDefault(false);
                  setVisible(true);
                }
              }
            }
          }
        },
        onKeyDown: (e) => {
          console.log(e.key);
          console.log(
            columnChooserProps.columns.findIndex(
              (col) => col.key === props.column.key
            )
          );
          if (e.key === 'Tab') {
            e.preventDefault();
            if (valueCode.includes(props.column.key)) {
              const currentColumnIndex = columnChooserProps.columns.findIndex(
                (col) => col.key === props.column.key
              );
              let nextColumnIndex = currentColumnIndex + 1;
              let currentColumnKeyName;
              let nextRow;
              if (nextColumnIndex >= columnChooserProps.columns.length) {
                nextColumnIndex = columnChooserProps.columns.findIndex(
                  (i) => i.key === valueCode[0]
                );
                currentColumnKeyName =
                  columnChooserProps.columns[nextColumnIndex].key;
                // nextColumnIndex = 1;
                // currentRowKeyValue = props.rowKeyValue + 1;
                const nextRowIndex = columnChooserProps.data.findIndex(
                  ({ type_id }) => +type_id === +props.rowKeyValue
                );
                if (nextRowIndex !== -1) {
                  let newRow = columnChooserProps.data[nextRowIndex + 1];
                  if (newRow) {
                    nextRow = newRow?.type_id;
                  } else {
                    console.log('no row after this');
                  }
                }
                // currentColumnKeyName = 'selection-cell';
              }
              // else if (currentColumnIndex === 1) {
              //   nextColumnIndex = columnChooserProps.columns.findIndex(
              //     (col) => col.key === valueCode[0]
              //   );
              //   currentRowKeyValue = props.rowKeyValue;
              //   currentColumnKeyName = valueCode[0];
              // }
              else {
                nextRow = props.rowKeyValue;
                currentColumnKeyName =
                  columnChooserProps.columns[currentColumnIndex + 1].key;
              }
              // if (props.column.key !== 'selection-cell' && props.value != e.target.value) {
              if (props.value != e.target.value) {
                // because fuck ka-table is why
                setSelectedEditableCell({
                  columnKey: currentColumnKeyName,
                  rowKeyValue: nextRow,
                });
                kaTableDispatch({
                  columnKey: props.column.key,
                  rowKeyValue: props.rowKeyValue,
                  type: 'UpdateCellValue',
                  value: e.target.value,
                });
              }
              changeColumnChooserProps({
                ...columnChooserProps,
                editableCells: [
                  {
                    columnKey: currentColumnKeyName,
                    rowKeyValue: nextRow,
                  },
                ],
              });
            }
          } else if (e.key === 'ArrowDown') {
            e.preventDefault();
            if (valueCode.includes(props.column.key)) {
              // const currentColumnIndex = columnChooserProps.columns.findIndex(
              //   (col) => col.key === props.column.key
              // );
              let nextRow;
              const nextRowIndex = columnChooserProps.data.findIndex(
                ({ type_id }) => +type_id === +props.rowKeyValue
              );
              if (nextRowIndex !== -1) {
                let newRow = columnChooserProps.data[nextRowIndex + 1];
                if (newRow) {
                  nextRow = newRow?.type_id;
                } else {
                  console.log('no row after this');
                }
              } else {
                console.log('does this case exist?');
              }
              if (props.value != e.target.value) {
                setSelectedEditableCell({
                  columnKey: props.column.key,
                  rowKeyValue: nextRow,
                });
                kaTableDispatch({
                  columnKey: props.column.key,
                  rowKeyValue: props.rowKeyValue,
                  type: 'UpdateCellValue',
                  value: e.target.value,
                });
              }
              changeColumnChooserProps({
                ...columnChooserProps,
                editableCells: [
                  {
                    columnKey: props.column.key,
                    rowKeyValue: nextRow,
                  },
                ],
              });
              console.log('go down');
            }
          } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            if (valueCode.includes(props.column.key)) {
              // const currentColumnIndex = columnChooserProps.columns.findIndex(
              //   (col) => col.key === props.column.key
              // );
              let previousRow;
              const nextRowIndex = columnChooserProps.data.findIndex(
                ({ type_id }) => +type_id === +props.rowKeyValue
              );
              if (nextRowIndex !== -1) {
                let newRow = columnChooserProps.data[nextRowIndex - 1];
                if (newRow) {
                  previousRow = newRow?.type_id;
                } else {
                  console.log('no row after this');
                }
              } else {
                console.log('does this case exist?');
              }
              if (props.value != e.target.value) {
                setSelectedEditableCell({
                  columnKey: props.column.key,
                  rowKeyValue: previousRow,
                });
                kaTableDispatch({
                  columnKey: props.column.key,
                  rowKeyValue: props.rowKeyValue,
                  type: 'UpdateCellValue',
                  value: e.target.value,
                });
              }
              changeColumnChooserProps({
                ...columnChooserProps,
                editableCells: [
                  {
                    columnKey: props.column.key,
                    rowKeyValue: previousRow,
                  },
                ],
              });
              console.log('go up');
            }
          } else if (e.key === 'Enter') {
            e.preventDefault();
            if (valueCode.includes(props.column.key)) {
              // const currentColumnIndex = columnChooserProps.columns.findIndex(
              //   (col) => col.key === props.column.key
              // );
              let nextRow;
              const nextRowIndex = columnChooserProps.data.findIndex(
                ({ type_id }) => +type_id === +props.rowKeyValue
              );
              if (nextRowIndex !== -1) {
                let newRow = columnChooserProps.data[nextRowIndex + 1];
                if (newRow) {
                  nextRow = newRow?.type_id;
                } else {
                  console.log('no row after this');
                }
              } else {
                console.log('does this case exist?');
              }
              if (props.value != e.target.value) {
                setSelectedEditableCell({
                  columnKey: props.column.key,
                  rowKeyValue: nextRow,
                });
                kaTableDispatch({
                  columnKey: props.column.key,
                  rowKeyValue: props.rowKeyValue,
                  type: 'UpdateCellValue',
                  value: e.target.value,
                });
              }
              changeColumnChooserProps({
                ...columnChooserProps,
                editableCells: [
                  {
                    columnKey: props.column.key,
                    rowKeyValue: nextRow,
                  },
                ],
              });
              console.log('go down');
            }
          } else if (e.key === 'ArrowRight') {
            e.preventDefault();
            if (valueCode.includes(props.column.key)) {
              const currentColumnIndex = columnChooserProps.columns.findIndex(
                (col) => col.key === props.column.key
              );
              let nextColumnIndex = currentColumnIndex + 1;
              let currentColumnKeyName;
              // let currentRowKeyValue;
              let nextRow;
              if (nextColumnIndex >= columnChooserProps.columns.length) {
                nextColumnIndex = columnChooserProps.columns.findIndex(
                  (i) => i.key === valueCode[0]
                );
                currentColumnKeyName =
                  columnChooserProps.columns[nextColumnIndex].key;
                console.log('🚀 ~ nextColumnIndex:', nextColumnIndex);
                // 1;
                const nextRowIndex = columnChooserProps.data.findIndex(
                  ({ type_id }) => +type_id === +props.rowKeyValue
                );
                if (nextRowIndex !== -1) {
                  let newRow = columnChooserProps.data[nextRowIndex + 1];
                  if (newRow) {
                    nextRow = newRow?.type_id;
                  } else {
                    console.log('no row after this');
                  }
                }
              }
              // else if (currentColumnIndex === 1) {
              //   nextColumnIndex = columnChooserProps.columns.findIndex(
              //     (col) => col.key === valueCode[0]
              //   );
              //   nextRow = props.rowKeyValue;
              //   currentColumnKeyName = valueCode[0];
              // }
              else {
                nextRow = props.rowKeyValue;
                currentColumnKeyName =
                  columnChooserProps.columns[currentColumnIndex + 1].key;
              }
              // if (props.column.key !== 'selection-cell' && props.value != e.target.value) {
              // because fuck ka-table is why
              if (props.value != e.target.value) {
                setSelectedEditableCell({
                  columnKey: currentColumnKeyName,
                  rowKeyValue: nextRow,
                });
                kaTableDispatch({
                  columnKey: props.column.key,
                  rowKeyValue: props.rowKeyValue,
                  type: 'UpdateCellValue',
                  value: e.target.value,
                });
              }
              changeColumnChooserProps({
                ...columnChooserProps,
                editableCells: [
                  {
                    columnKey: currentColumnKeyName,
                    rowKeyValue: nextRow,
                  },
                ],
              });
            }
          } else if (e.key === 'ArrowLeft') {
            e.preventDefault();
            if (valueCode.includes(props.column.key)) {
              const currentColumnIndex = columnChooserProps.columns.findIndex(
                (col) => col.key === props.column.key
              );
              let nextColumnIndex = currentColumnIndex - 1;
              let currentColumnKeyName =
                columnChooserProps.columns[nextColumnIndex].key;
              // let currentRowKeyValue;
              let nextRow = props.rowKeyValue;
              const firstPriceValueColumnIndex =
                columnChooserProps.columns.findIndex(
                  (i) => i.key === valueCode[0]
                );
              console.log(
                '🚀 ~ firstPriceValueColumnIndex:',
                firstPriceValueColumnIndex
              );
              if (nextColumnIndex < firstPriceValueColumnIndex) {
                // const lastPriceValueColumnIndex = columnChooserProps.columns.findIndex((i) => i.key === valueCode[valueCode.length -1])
                // nextColumnIndex = lastPriceValueColumnIndex
                currentColumnKeyName = valueCode[valueCode.length - 1];
                const nextRowIndex = columnChooserProps.data.findIndex(
                  ({ type_id }) => +type_id === +props.rowKeyValue
                );
                if (nextRowIndex !== -1) {
                  let newRow = columnChooserProps.data[nextRowIndex - 1];
                  if (newRow) {
                    nextRow = newRow?.type_id;
                  } else {
                    console.log('no row after this');
                  }
                }
                // console.log("🚀 ~ lastPriceValueColumnIndex:", lastPriceValueColumnIndex)
                // console.log('go up now')
              } else {
                // const nextRowIndex = columnChooserProps.data.findIndex(({type_id}) => +type_id === +props.rowKeyValue)
                // if(nextRowIndex !== -1) {
                // let newRow = columnChooserProps.data[nextRowIndex];
                // if (newRow) {
                //   nextRow = newRow?.type_id;
                // } else {
                //   console.log('no row after this');
                // }
                // }
              }
              if (props.value != e.target.value) {
                setSelectedEditableCell({
                  columnKey: currentColumnKeyName,
                  rowKeyValue: nextRow,
                });
                kaTableDispatch({
                  columnKey: props.column.key,
                  rowKeyValue: props.rowKeyValue,
                  type: 'UpdateCellValue',
                  value: e.target.value,
                });
              }
              changeColumnChooserProps({
                ...columnChooserProps,
                editableCells: [
                  {
                    columnKey: currentColumnKeyName,
                    rowKeyValue: nextRow,
                  },
                ],
              });
              console.log('go left');
              console.log('🚀 ~ nextRow:', nextRow);
            }
          }
        },
        onClick: (e) => {
          // if (props?.rowKeyValue === 55587) {
          //   kaTableDispatch(updateGroupsExpanded(props.rowKeyValue))
          // }
        },
      }),
    },
    dataRow: {
      elementAttributes: rowElementAttributes,
    },
    noDataRow: {
      content: () => 'No Data Available',
    },
    groupRow: {
      elementAttributes: (props) => ({
        className: `group-row-${props?.column?.key}`,
        hidden:
          (props.column.key === 'location_address' &&
            props.groupKey[props.groupIndex] === undefined) ||
          (props.column.key === 'area' &&
            props.groupKey[props.groupIndex] === '') ||
          !!(
            props?.column?.key === 'parent_line' &&
            props.groupKey[props.groupIndex] <= '0'
          ),
        onClick: (e) => {
          if (props?.column?.key === 'parent_line') {
            kaTableDispatch(updateGroupsExpanded(props.groupKey));
          }
          if (props?.column?.key === 'add-icon') {
            kaTableDispatch(updateGroupsExpanded(props.groupKey));
          }
        },
        onContextMenu: (event, cellProps) => {
          event.preventDefault(false);
        },
      }),
      childComponents: {
        dataRow: {
          elementAttributes: (props) => ({
            className: 'group-line-child-row',
          }),
        },
      },
      contentColSpan: 1,
      content: (props) => {
        switch (props?.column?.key) {
          case 'parent_line':
            return DataRowLine(props);
          default:
            return null;
        }
      },
    },
    groupCell: {
      content: (props) => {
        switch (props?.column?.key) {
          case 'area':
            return (
              <Box className="area_flex">
                <Typography>
                  {props?.groupKey[props.groupIndex]?.length > 100
                    ? props.groupKey[props.groupIndex]
                      .slice(0, 30)
                      ?.toUpperCase() + '...'
                    : props.groupKey[props.groupIndex]?.toUpperCase()}
                </Typography>{' '}
                <IconButton
                  className="icon_btn edit"
                  onClick={() => handleEditArea(props.groupKey)}
                >
                  <Edit />
                </IconButton>
              </Box>
            );
          default:
            return null;
        }
      },
    },
    cellText: {
      elementAttributes: (props) => ({
        // title: props?.column?.key === 'ref' && props?.rowData?.type === 'asset' ? `Location Name : ${props.rowData?.location_address} \n \n Area Name:${props?.rowData?.area}` : props?.column?.key === 'ref' && props?.rowData?.type === 'line' ? `Location Name : ${props.rowData?.location_address}` : props?.column?.key === 'selection-cell' ? '' : props?.column?.key === 'add-icon' ? '' :
        //   valueCode?.includes(props?.column?.key) ? '' : props?.column?.key === 'flagged' ? '' : props?.column?.key === 'is_not_found' ? '' : props?.column?.key === 'condition_value' ? `${convertConditionValueToCondition(props?.value)}` : props?.column?.key === 'asset_class_code' ? `${convertClassValueToClass(props.value)}` : `${props?.value}` === 'null' ? '' : `${props?.value}`,
        title:
          props?.column?.key === 'ref' && props?.rowData?.type === 'asset'
            ? `Location Name : ${props.rowData?.location_address} \n \n Area Name:${props?.rowData?.area}`
            : props?.column?.key === 'ref' && props?.rowData?.type === 'line'
              ? `Location Name : ${props.rowData?.location_address}`
              : props?.column?.key === 'selection-cell'
                ? ''
                : props?.column?.key === 'add-icon'
                  ? ''
                  : valueCode?.includes(props?.column?.key)
                    ? ''
                    : props?.column?.key === 'flagged'
                      ? ''
                      : props?.column?.key === 'is_not_found'
                        ? ''
                        : props?.column?.key === 'condition_value'
                          ? `${convertConditionValueToCondition(props?.value)}`
                          : props?.column?.key === 'asset_class_code'
                            ? `${convertClassValueToClass(props.value)}`
                            : `${props?.value}` === 'null'
                              ? ''
                              : `${props?.value}`,
      }),
      content: (props) => {
        if (valueCode?.includes(props?.column?.key)) {
          if (props?.rowData?.type === 'line' && props?.value == 0) {
            return <Typography style={{ color: 'transparent' }}>0</Typography>;
          }
          if (props?.value?.toString() === 'NaN' || props?.value === '0') {
            return <></>;
          }
          if (
            !props?.value ||
            (!props?.value && props?.rowData?.type === 'line')
          ) {
            return <Typography style={{ color: 'transparent' }}>0</Typography>;
          }
          if (props?.rowData?.type !== 'line') {
            return (
              <Typography>
                {props?.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </Typography>
            );
          } else {
            return (
              <Typography
                style={{
                  color: +props.rowData[`${props.column.key}-color`]
                    ? 'red'
                    : 'black',
                  fontWeight: 'bold',
                }}
              >
                {checkType(props?.value)}
              </Typography>
            );
          }
        }
        if (
          props?.column?.key === 'selection-cell' &&
          props.rowData?.type == 'line'
        ) {
          // setSelectedRow(props.selectedRows[0]) //this is add for insert new line asset custom menu item
          // return <SelectionCell {...props} />;
          //! this is new and needs some well round testing
          return (
            <SelectionLine
              {...props}
              rows={columnChooserProps?.data} // selected line not check uncheck after drag & drop the line
            />
          );
        } else if (props?.column?.key === 'selection-cell') {
          // setSelectedRow(props.selectedRows[0]) //this is add for insert new line asset custom menu item
          return <SelectionCell {...props} />;
        } else if (
          props?.column?.key === 'add-icon' &&
          props?.rowData?.type == 'line'
        ) {
          // return <SwitchIcon handleSelect={handleSelect} showIcon={showIcon} />;
          {
            /* !just handling it for now, will cleanup later */
          }
          return <></>;
        } else if (props?.column?.key == 'add-icon') {
          return <></>;
        } else if (
          props?.column?.key == 'quantity' &&
          props.rowData.type === 'asset'
        ) {
          if (props.rowData?.quantity_type === 'lot') {
            return <Typography>{props.rowData?.quantity_type}</Typography>;
          } else {
            return <Typography>{props.value}</Typography>;
          }
        } else if (props?.column?.key === 'serial_number') {
          if (props.rowData?.type === 'line') {
            return <></>;
          }
        } else if (props?.column?.key === 'quantity') {
          if (props.rowData?.type === 'line') {
            return <></>;
          }
        }
        // else if (props?.column?.key === 'line_name') {
        //   if (props.rowData?.type === 'line') {
        //     return <Typography>{props.value}</Typography>
        //   }
        // }
        else if (props?.column?.key === 'flagged') {
          if (props.rowData?.type === 'line') {
            return <></>;
          } else {
            return (
              <>
                {props?.rowData?.flagged === 1 ? (
                  <IconButton
                    id={props.rowKeyValue}
                    className="icon_btn_flag delete"
                    onClick={handleCheck(
                      props.rowKeyValue,
                      props.rowData.flagged
                    )}
                  >
                    <FlagIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    id={props.rowKeyValue}
                    className="icon_btn_flag_default delete"
                    onClick={handleCheck(
                      props.rowKeyValue,
                      props.rowData.flagged
                    )}
                  />
                )}
              </>
            );
          }
        } else if (props?.column?.key === 'is_not_found') {
          if (props.value === 2) {
            return <CloseIcon fontSize="small" color="warning" />;
          } else if (props.value === 1) {
            return <CheckIcon fontSize="small" color="success" />;
          }
        }
        // else if (valueCode?.includes(props?.column?.key)) {
        //   return props.value !== '0' ? props.value
        //     // <CustomTextBox value={props.value}/>
        //     : ' ';
        // }
        else if (props?.column?.key === 'asset_class_code') {
          return props.value ? (
            <SelectionDropdown
              selectedValue={props?.value}
              data={assetsState?.assetClass?.assetClass}
              handleChange={(e) => handleClassChange(e, props)}
              handleToggle={(id) => handleToggleDropdown(id, 'class')}
              // toggleClass={toggleClass}
              // toggleClass={true}
              toggleClass={dropDownType === 'class'}
              rowId={props.rowKeyValue}
              currentRowId={currentActiveRowId}
            />
          ) : (
            <SelectionDropdown
              selectedValue="NA" //just a placeholder
              // data={assetsState?.assetClass?.assetClass}
              data={[
                {
                  asset_class_id: null,
                  code: '',
                },
                ...assetsState?.assetClass?.assetClass,
              ]}
              handleChange={(e) => handleClassChange(e, props)}
              handleToggle={(id) => handleToggleDropdown(id, 'class')}
              // toggleClass={toggleClass}
              // toggleClass={true}
              toggleClass={dropDownType === 'class'}
              rowId={props.rowKeyValue}
              currentRowId={currentActiveRowId}
            />
          );
        } else if (props?.column?.key === 'condition_value') {
          return props.value ? (
            <ConditionDropdown
              selectedValueCondition={props?.value}
              conditionData={assetsState?.getConditions?.getConditions}
              handleConditionChange={(e) => handleConditionChange(e, props)}
              handleToggleCondition={(id) => handleToggleDropdown(id, 'condition')}
              // toggleCondition={toggleCondition}
              toggleCondition={dropDownType === 'condition'}

              rowIdCondition={props.rowKeyValue}
              currentRowIdCondition={currentActiveRowId}
            />
          ) : (
            <ConditionDropdown
              selectedValueCondition="NA" //just for a placeholder
              conditionData={assetsState?.getConditions?.getConditions}
              handleConditionChange={(e) => handleConditionChange(e, props)}
              handleToggleCondition={(id) => handleToggleDropdown(id, 'condition')}
              // toggleCondition={toggleCondition}
              toggleCondition={dropDownType === 'condition'}
              rowIdCondition={props.rowKeyValue}
              currentRowIdCondition={currentActiveRowId}
            />
          );
        }
      },
    },
    filterRowCell: {
      content: (props) => {
        if (props?.column?.key === 'selection-cell') {
          return <></>;
        } else if (props?.column?.key === 'add-icon') {
          return <></>;
        }
      },
    },
    headCell: {
      elementAttributes: (props) => ({
        title:
          props?.column?.key === 'add-icon' ||
            props?.column?.key === 'selection-cell'
            ? ''
            : `${props?.column?.title}`,
      }),
      content: (props) => {
        if (props?.column?.key === 'selection-cell') {
          return (
            <SelectionHeader
              {...props}
              areAllRowsSelected={kaPropsUtils.areAllFilteredRowsSelected(
                columnChooserProps
              )}
            />
          );
        } else if (props?.column?.key === 'add-icon') {
          return (
            <Box className="align_switch">
              {/* <SwitchIcon handleSelect={handleSelect} showIcon={showIcon} /> */}
              <></> {/* ! just handling it for now, will cleanup later*/}
            </Box>
          );
        }
      },
    },
    pagingSizes: {
      elementAttributes: (props) => ({
        title: 'Selected Page',
      }),
      content: (props) => <PageSize {...props} />,
    },
    pagingIndex: {
      elementAttributes: ({ isActive }) => ({
        className: `page-item ${isActive ? 'active' : ''}`,
      }),
    },
    pagingPages: {
      elementAttributes: () => ({
        className: 'pagination',
      }),
    },
  };

  // ! updated
  // useEffect(() => {
  //   if (lineState?.messageData.status === 'success') {
  //     setMessageData({ message: lineState?.messageData?.message, status: 'success' })
  //   }
  // }, [lineState?.messageData]);

  // ! updated
  useEffect(() => {
    return () => {
      localStorage.removeItem('current_active_page');
    };
  }, []);

  // ! updated partly
  const updateflagged = async (flagType, id) => {
    const keyExist = columnChooserProps?.['selectedRows'];
    let updateFlaggedIds = [];
    let updateUnFlaggedIds = [];
    if (keyExist) {
      try {
        for (const columnItem of columnChooserProps?.selectedRows) {
          for (const rowItem of rows) {
            if (rowItem.type_id === columnItem) {
              if(rowItem.flagged === 1) {
                updateUnFlaggedIds.push(columnItem);
              } else if (rowItem.flagged === 0) {
                updateFlaggedIds.push(columnItem)
              }
              break;
            }
          }
          
        }
        if (flagType === 'Flag Asset' || flagType === 'UnFlag Asset') {
          let obj = {
            flagged_ids: updateUnFlaggedIds.join(',') ?? flagId, // ? not sure why the flagId is being used for as a fallback
            projectIdCrm: decodedProjectId,
            unflagged_ids: updateFlaggedIds.join(',') ?? flagId,
          };
          setIsLoading(true)
          // reduxDispatch(postFlaggedAction(obj));
          const response = await postFlagged(obj);
          setMessageData({message: response.msg, status: 'success'})
          setFetchAssets(true)
          // setGetAssetListData(true);
        }
      } catch (error) {
        setMessageData({message: 'Failed to flag/unflag', status: 'warning'})
        console.log('failed to update flag'); //! no need to handle it here since we did not update the table anyway
      }
    } else {
      try {
        const isFlagged = id === 1 ? 0 : 1;
        const data = {
          modified_by: Number(userId),
          type_id: flagId,
          flagged: isFlagged,
        };
        let allRows = [...rows];
        for (const row of allRows) {
          if (row.type_id === flagId) {
            row.flagged = isFlagged;
          }
        }
        setRows(allRows);
        changeColumnChooserProps({ ...columnChooserProps, allRows }); // !the table is not getting updated in this case so I am now updating the tableProps to reflect the changes
        await postFlagged(data); //! we no longer wait for the flag to update but rather update the table and if something does go wrong we handle it in the catch block
      } catch (error) {
        const isFlagged = id === 1 ? 1 : 0; //! we revert the action if the api fails, so if we flagged the asset we un-flag it and vice-versa
        let allRows = [...rows];
        for (const row of allRows) {
          if (row.type_id === flagId) {
            row.flagged = isFlagged;
          }
        }
        setRows(allRows);
        changeColumnChooserProps({ ...columnChooserProps, allRows }); // !the table is not getting updated in this case so I am now updating the tableProps to reflect the changes
      }
    }
  };

  // ? - 145
  const handleConfirmation = () => {
    setOpenConfirmation(!openConfirmation);
  };

  // ! updated partly
  const handleDelete = async () => {
    let deleteIds = columnChooserProps?.selectedRows?.join(',') ?? flagId;
    try {
    setIsLoading(true)
    await deleteAssets(deleteIds)
    setMessageData({message: 'Delete Asset(s) Successfully.', status: 'success'})
    setFetchAssets(true)
  } catch (error) {
    setMessageData({message: 'Failed To Delete Asset(s).', status: 'success'})
  } finally {
    setIsLoading(false)
    setVisible(false); // ? wtf is this?
    setOpenConfirmation(false)
    }
  };

  // ? - 147 - not sure if this needs to be updated and what/how to update
  const handleMenuItem = (key) => {
    const allRows = [...rows];
    const assetRows = allRows.filter(({ type }) => type !== 'line');
    // it was getting impossible to handle the other way around

    localStorage.removeItem('type_id');
    let index;
    assetRows?.map(({ type_id }, i) => {
      if (type_id === rowData?.type_id) {
        index = i;
      }
    });
    setItem(key);
    if (key.name === 'Insert New Area') {
      setOpenDialog(true);
      setVisible(false);
    } else if (key.name === 'Block Move') {
      setOpenDialog(true);
      setVisible(false);
    } else if (key.name === 'Reassign Asset') {
      setOpenDialog(true);
      setVisible(false);
    } else if (key.name === 'Insert New Line') {
      if (selectItems?.length > 0) {
        setInputLineValues({
          asset_class_id: 1,
          condition: 'Good',
          line_text2: ', Consisting of:',
        });
        setOpenDialog(true);
        setVisible(false);
      } else {
        setMessageData({ message: 'Please Select the Asset First', status: 'warning' })
      }
    } else if (key === 'Flag Asset') {
      setFlagId(rowData.type_id);
      setVisible(false);
      updateflagged(key, rowData?.flagged);
    } else if (key === 'UnFlag Asset') {
      setFlagId(rowData.type_id);
      setVisible(false);
      updateflagged(key, rowData?.flagged);
    } else if (key.name === 'Delete Asset') {
      handleConfirmation();
    } else if (key.name === 'Copy Asset Below') {
      let obj = {
        copy_type_id: rowData.type_id,
      };
      // reduxDispatch(copyAssetBelowAction(obj));
      copyAssetBelow(obj).then(() => {
        setMessageData({message: 'Asset Copy Successfully.', status: 'success'})
        setVisible(false);
        setFetchAssets(true)
      })
    } else if (key.name === 'Insert Asset Above') {
      localStorage.setItem('editMode', 1);
      localStorage.setItem('dropTo', +rowData?.area_asset_order);
      localStorage.setItem('area', rowData.area);
      localStorage.setItem('insertPosition', 'above');
      history.push({ pathname: `asset-detail/` });
      return;
      localStorage.setItem('assetDropTo', +rowData?.area_asset_order);
      setFlagId(rowData.type_id);
      history.push({
        pathname: 'asset-detail',
        state: {
          data: {
            rowData,
            index,
            key,
            rows: assetRows,
            valueCode,
            sleekMode: true,
          },
        },
      });
    } else if (key.name === 'Insert Asset Below') {
      localStorage.setItem('editMode', 1);
      localStorage.setItem('dropTo', +rowData?.area_asset_order);
      localStorage.setItem('insertPosition', 'below');
      localStorage.setItem('area', rowData.area);
      history.push({ pathname: `asset-detail/` });
      // localStorage.setItem('assetDropTo', +rowData?.area_asset_order)
      // setFlagId(rowData.type_id);
      // history.push({
      //   pathname: 'asset-detail',
      //   state: {
      //     data: {
      //       rowData,
      //       index,
      //       key,
      //       rows: assetRows,
      //       valueCode,
      //       sleekMode: true,
      //     },
      //   },
      // });
    } else {
      localStorage.setItem('editMode', 1);
      localStorage.setItem('type_id', rowData.type_id);
      // localStorage.removeItem('type_id')
      // ? - still pending as part of the ref on edit asset
      //       var edit_asset_action = [
      //   "Edit Asset",
      //   function ($itemScope, $event, row) {
      //     $scope.flag = false;
      //     row = angular.fromJson(row);
      //     $cookieStore.put("location_id", parseInt(row.location_id));
      //     $location.path("/assetdetail/" + row.type_id);
      //   },
      // ];
      history.push({ pathname: `asset-detail/${rowData.type_id}` });
      // const updatedRowData = rows.find(
      //   (data) => data.type_id === rowData.type_id
      // );
      // setIsLoading(true)
      // getAssetInEdit({ ...refPayload, project_id_crm: decodedProjectId, projectId: updatedRowData.project_id, type_id: +updatedRowData?.type_id }).then(({ current_ref_no }) => {
      //   history.push({
      //     pathname: 'asset-detail' + `/${rowData.type_id}`,
      //     state: { data: { rowData: { ...rowData, ref: current_ref_no }, key, index, valueCode, rows: assetRows } },
      //   });
      // })
    }
  };
  // ?
  const handleDialog = () => {
    setOpenDialog(!openDialog);
  };

  // todo - 149 ? what the absolute fuck?
  const handleChangeArea = (e) => {
    let value = e.target.value;
    if (value?.length <= 250) {
      setAddArea(e.target.value);
    }
  };

  // todo - 150 - after discussion with Munna
  const handleAddArea = () => {
    let value = {
      'Forced Liquidation Value': rowData.FLV,
      'Orderly Liquidation Value': rowData.OLV,
    };
    const payload = Object.assign(rowData, { value: value });

    if (!addArea) {
      setMessageData({ message: 'Please Enter Area Name', status: 'warning' })
      return;
    }
    let addObj = {
      action: 'insertNewArea',
      new_name: addArea,
      project_id_crm: decodedProjectId,
      row: payload,
    };
    getAssetList(addObj)
      .then((res) => {
        if (res.msgCode === 1) {
          setMessageData({ message: res.msg, status: 'warning' })
        } else {
          setMessageData({ message: 'Area has been Added Successfully!', status: 'warning' })
          setAddArea(null);
          setFetchAssets(true)
          setOpenDialog(false);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  // todo - 151 - this is being used for setting the value in insertLine api so definitely change how this shit works - later ? x1
  useEffect(() => {
    let priceVal = [];
    const finalValue = assetData?.locationPriceValues;
    finalValue?.forEach((item) => {
      for (const key in inputLineValues) {
        if (item.value_short_name === key) {
          let obj = {
            name: item.value_code,
            value: inputLineValues[key],
          };
          priceVal.push(obj);
        }
      }
    });
    setValue(priceVal);
  }, [inputLineValues]);

  // todo - 152 - x1
  const transformedPriceVal = value?.reduce((result, item) => {
    result[item.name] = item.value;
    return result;
  }, {});

  // todo - 153 - x1
  const handleInsertNewLine = () => {
    // Please enter atleast one field from Make, Text1 or Asset Type.
    if (
      inputLineValues?.line_name ||
      inputLineValues?.make ||
      inputLineValues?.asset_type
    ) {
      let obj = {
        selectedAsset: selectItems,
        asset_type_id: inputLineValues?.asset_type_id,
        asset_class_id: inputLineValues?.asset_class_id,
        condition: inputLineValues?.condition,
        appraiser: rowData?.user_id,
        line_text2: inputLineValues?.line_text2,
        btn: 'inactive',
        priceVal: transformedPriceVal,
        make_id: inputLineValues?.make_id,
        make: inputLineValues?.make,
        line_name: inputLineValues?.line_name,
        asset_no: inputLineValues?.asset_no,
        year: inputLineValues?.year,
        appraiser_note: inputLineValues?.appraiser_note,
        cost: inputLineValues?.cost,
        created_by: Number(userId),
        modified_by: Number(userId),
        action: 'insertNewAsset',
        project_id_crm: decodedProjectId,
      };
      localStorage.setItem(
        'current_active_page',
        columnChooserProps.paging.pageIndex
      );
      getAssetList(obj)
        .then((res) => {
          if (res === undefined) {
            setMessageData({ message: 'Something Went Wrong', status: 'warning' })
          } else {
            setMessageData({ message: 'Line has been created !!', status: 'success' })
            localStorage.removeItem('type_id');
            reduxDispatch(assetsListActions.setDoFetchAssetList(true));
            // setOpenDialog(false);
            setInputLineValues({});
            changeColumnChooserProps({
              ...columnChooserProps,
              selectedRows: [],
            });
            setSelectItems({});
            setItem();
            handleDialog();
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    } else {
      setMessageData({ message: 'Please enter atleast one field from Make, Text1 or Asset Type', status: 'success' })
    }
  };

  // todo - 154 - later please, needs to be updated....
  const handleReassign = (data) => {
    getAssetList(data)
      .then((res) => {
        if (res === undefined) {
          setMessageData({ message: 'Something Went Wrong', status: 'warning' })
        } else {
          setMessageData({ message: res?.msg, status: 'success' })
          setFetchAssets(true)
          setOpenDialog(false);
        }
      })
      .catch((error) => { 
        setMessageData({ message: 'Something Went Wrong', status: 'warning' })
      });
  };

  // ! updated
  const handleMove = async (data) => {
    localStorage.setItem('alreadyScrolled', 0);
    const res = await blockMoveAsset(data)
    setMessageData({message: res.data.msg, status: 'success'})
    setFetchAssets(true)
    setOpenDialog(false);
  };
  // ! partly updated - will be done as part of add/edit line feature
  const handleMenuItem2 = (key) => {
    if (key.name === 'Edit Line') {
      history.push({
        pathname: 'line-detail' + `/${flagId}`,
        // state: { data: { parentRowData, key, route: 'asset-list' } },
        // valueCode: valueCode,
      });
    } else if (key.name === 'Copy Line') {
      history.push({
        pathname: 'line-detail' + `/${flagId}`,
        // state: {
        //   data: {
        //     parentRowData,
        //     key,
        //     route: 'asset-list',
        //   },
        // },
        // valueCode: valueCode,
      });
    }
  };

  // let typeId = localStorage.getItem('type_id');
  // const lineData = rows.find(({type})=> type==='line')
  // ! was done by me and seemed right back then but will have a look
  const getTable = useMemo(() => {
    const PAGE_SIZE = localStorage.getItem('itemsPerPage')
      ? localStorage.getItem('itemsPerPage')
      : 50;
    const CURRENT_PAGE = localStorage.getItem('current_active_page')
      ? { pageIndex: +localStorage.getItem('current_active_page') }
      : null;
    // localStorage.setItem('itemsPerPage', columnChooserProps.paging.pageSize);
    resetFilterInDataGrid()
    return (
      <Table
        {...columnChooserProps}
        paging={{
          ...columnChooserProps.paging,
          pageSize: +PAGE_SIZE,
          CURRENT_PAGE,
        }}
        childComponents={childComponents}
        dispatch={kaTableDispatch}
        // treeGroupKeyField={'treeGroupId'}
        // groups={[{columnKey: column.key === 'add-icon'}]}
        search={({ searchValue: search, rowData, column }) => {
          if (column.key === rowData?.flagged) {
            return (
              (search === 'false' && !rowData.flagged) ||
              (search === 'true' && rowData.flagged)
            );
          }
        }}
        // searchText={searchValue}
        noData={{
          text: 'No Data Found',
        }}
      />
    );
  }, [columnChooserProps, filterAssetInDataGrid]);

  // ! updated
const handleFocus = () => {
  const allowedValues = ['no value', 'leased', 'not valued'];
  setInputLineValues((prevValues) => {
    const updatedInputValues = { ...prevValues };
    valueCode.forEach((val) => {
      const currentValue = prevValues[val]?.toLowerCase()?.trim();
      if (
        !allowedValues.includes(currentValue) &&
        !(+prevValues[val] > 0)
      ) {
        updatedInputValues[val] = '';
      }
    });
    return updatedInputValues;
  });
};
  // ! updated
const handleChangeLine = (e) => {
  const { name, value } = e.target;
  const isValidLength = (inputValue, maxLength) => inputValue.length <= maxLength;
  let newValue;
  if (name === 'line_text2' && !isValidLength(value, 100)) {
    return;
  } else if (name === 'line_name' && !isValidLength(value, 500)) {
    return;
  } else {
    newValue = value;
  }
  setInputLineValues((prevValues) => ({
    ...prevValues,
    [name]: newValue,
  }));
};

  // ? no need to update
  const handleSelectedForLineAndAssetTypeForLine = (selected) => {
    let label;
    if (selected[0]?.label) label = true;
    setInputLineValues({
      ...inputLineValues,
      selectedAssetType: selected,
      asset_type: label ? selected[0]?.label : selected[0]?.asset_type,
      asset_type_id: label ? null : selected[0]?.asset_type_id,
      asset_class_id: +selected[0]?.class_id ? +selected[0]?.class_id : 0,
    });
  };

  // ? no need to update
  const handleKeyDownForTypeAheadForLine = (e) => {
    const { placeholder, value } = e.target;
    if (!value) return;
    if (e.keyCode === 9) {
      if (placeholder === 'Search Make') {
        const makeId = makeList?.find(({ make }) => make === value)?.make_id;
        setInputLineValues({
          ...inputLineValues,
          selectedMake: [{ make: value }], 
          make: value,
          make_id: makeId,
        });
      }
      if (placeholder === 'Search Asset Types') {
        const assetTypeValue = filteredAssetTypes?.find(
          ({ asset_type }) => asset_type === value
        );
        setInputLineValues({
          ...inputLineValues,
          selectedAssetType: [{ asset_type: value }],
          asset_type: value,
          asset_type_id: assetTypeValue?.asset_type_id,
          asset_class_id: assetTypeValue?.class_id,
        });
      }
    }
  };

  // ? no need to update
  const handleCustomTypeAheadForMakeForLine = (makeValue) => {
    if (Array.isArray(makeValue)) return;
    setInputLineValues({
      ...inputLineValues,
      selectedMake: [{ make: makeValue }],
      make: makeValue,
    });
  };

  // ? no need to update
  const handleSelectedForLines = (selected, type) => {
    let label;
    // we set lable in case we are adding a custom value and not one that is already in the list
    if (selected[0]?.label) label = true;
    if (selected.length === 0) return;
    switch (type) {
      // if we have a label, we set the values accordingly based on a ternary check
      case 'make':
        setInputLineValues({
          ...inputLineValues,
          selectedMake: selected,
          make: label ? selected[0]?.label : selected[0]?.make,
          make_id: label ? null : selected[0]?.make_id,
        });
        break;
      case 'assetType':
        setInputLineValues({
          ...inputLineValues,
          selectedAssetType: selected,
          asset_type: label ? selected[0]?.label : selected[0]?.asset_type,
          asset_type_id: label ? null : selected[0]?.asset_type_id,
          asset_class_id: +selected[0]?.class_id ?? 0,
        });
        break;
      default:
        break;
    }
  };

  // updated
  // function countOccurrences(arr, val) {
  //   return arr?.reduce((count, item) => {
  //     return count + (item === val ? 1 : 0);
  //   }, 0);
  // }

  // updated
  // const assetCount = columnChooserProps?.selectedRows?.map((item) => {
  //   console.log("🚀 ~ assetCount ~ columnChooserProps?.selectedRows:", columnChooserProps?.selectedRows)
  //   return rows?.filter((key) => {
  //     return key.type_id === item && key.type !== 'line';
  //   }).length;
  // });

  // updated
  // const assetCountArray = countOccurrences(assetCount, 1);
  const deleteAssetCount = columnChooserProps?.selectedRows?.reduce((count, item) => {
  console.log("🚀 ~ assetCount ~ columnChooserProps?.selectedRows:", columnChooserProps?.selectedRows);
  return count + rows?.filter((key) => key.type_id === item && key.type !== 'line').length;
}, 0);


  const getPriceValues = (type) => {
    console.log('hello');
    console.log(formFieldOptions.locationPriceValues);
    // if(type == 'line') {}
    return formFieldOptions.locationPriceValues.map((item, index) => {
      if (type == 'line') {
        let percentageValue =
          (lineFormData[item.value_short_name] / lineFormData?.cost) * 100;
        return (
          <Grid item md={2} key={index}>
            <Box className="input_box">
              <Typography>{item.value_short_name} ($):</Typography>
              {!isNaN(lineFormData[item.value_short_name]) &&
              lineFormData?.cost > 0 ? (
                <Typography>{percentageValue.toFixed(2)}% of Cost</Typography>
              ) : (
                ''
              )}
              <CustomTextBox
                className={
                  validateCost(item.value_short_name)
                    ? 'coral_background'
                    : null
                }
                // className={'coral_background'}
                onChange={(e) => handleLineFormChange(e, 'priceValue')}
                name={item.value_short_name}
                value={lineFormData[item.value_short_name]}
                onBlur={handleAssetFocus}
              />
            </Box>
          </Grid>
        );
      }
      let percentageValue =
        (formData[item.value_short_name] / formData?.cost) * 100;
      return (
        <Grid item md={2} key={index}>
          <Box className="input_box">
            <Typography>{item.value_short_name} ($):</Typography>
            {!isNaN(formData[item.value_short_name]) && formData?.cost > 0 ? (
              <Typography>{percentageValue.toFixed(2)}% of Cost</Typography>
            ) : (
              ''
            )}
            <CustomTextBox
              className={
                validateCost(item.value_short_name) ? 'coral_background' : null
              }
              // className={'coral_background'}
              onChange={(e) => handleChange(e, 'priceValue')}
              name={item.value_short_name}
              value={formData[item.value_short_name]}
              onBlur={handleAssetFocus}
            />
          </Box>
        </Grid>
      );
    });
  };

  return (
    <DataGridStyled className="rows-reordering-demo" id="my-component">
      {isLoading ? <Loader /> : null}
      <ParentMenu
        visible={open}
        posX={posX}
        posY={posY}
        handleClose={() => setOpen(false)}
        handleMenuItem={handleMenuItem2}
        rowData={rowData}
      />
      <CustomMenu
        visible={visible}
        posX={posX}
        posY={posY}
        handleClose={() => setVisible(false)}
        handleMenuItem={handleMenuItem}
        rowData={rowData}
        inputdata={inputdata}
      />
      {item && item.id == 3 ? (
        <InsertAreaDialog
          openDialog={openDialog}
          item={item}
          handleDialog={handleDialog}
          handleAdd={handleAddArea}
          handleChangeArea={handleChangeArea}
          addArea={addArea}
        />
      ) : (
        ''
      )}
      {item && item.id == 2 ? (
        <BlockMoveDialog
          openDialog={openDialog}
          item={item}
          handleDialog={handleDialog}
          rowData={rowData}
          selectedAsset={selectItems}
          handleMove={handleMove}
          maxRefNo={assetResponse.max_ref_no}
          minRefNo={assetResponse.min_ref_no}
          appraiserData={appraiserData}
        />
      ) : (
        ''
      )}
      {item && item.id == 7 ? (
        <ReassignDialog
          openDialog={openDialog}
          appraiserData={appraiserData}
          item={item}
          handleDialog={handleDialog}
          handleReassign={handleReassign}
          rowData={rowData}
          assetResponse={assetResponse}
          decodedProjectId={decodedProjectId}
        />
      ) : (
        ''
      )}
      {item && item.id == 4 ? (
        <InsertNewLineDialog
          getPriceValues={() => getPriceValues('line')}
          handleDialog={handleDialog}
          openDialog={openDialog}
          makeList={makeList}
          handleSelected={handleSelectedForLines}
          handleCustomTypeAheadForMakeForLine={
            handleCustomTypeAheadForMakeForLine
          }
          handleKeyDownForTypeAheadForLine={handleKeyDownForTypeAheadForLine}
          handleSelectedForLineAndAssetTypeForLine={
            handleSelectedForLineAndAssetTypeForLine
          }
          inputValues={inputLineValues}
          handleFocus={handleFocus}
          condition={condition}
          handleChange={handleChangeLine}
          assetTypes={filteredAssetTypes}
          handleInsertNewLine={handleInsertNewLine}
          assetData={assetData}
          rowData={rowData}
          flag="datagrid"
          valueCodes={valueCode}
          item={item}
        />
      ) : (
        // <InsertNewLineDialog
        //   openDialog={openDialog}
        //   item={item}
        //   handleDialog={handleDialog}
        //   rowData={rowData}
        //   // handleChange={handleChangeLine}
        //   handleInsertNewLine={handleInsertNewLine}
        //   valueCodes={valueCode}
        //   flag = 'datagrid'
        //   selectItems={selectItems}
        // />
        ''
      )}
      <EditPopup
        handleEditArea={handleEditArea}
        // areaEdit={areaEdit}
        areaName={areaName}
        // isEnable={isEnable}
        newAreaName={newAreaName}
        handleChangeAreaName={handleChangeAreaName}
        handleUpdateArea={handleUpdateArea}
      />
      <ColumnToggle
        tableProps={columnProps}
        dispatch={dispatch}
        columnSetting={columnSetting}
        handleSidebar={handleSidebar}
      />
      {getTable}
      <ConfirmationAlert
        handleDelete={handleDelete}
        openConfirmation={openConfirmation}
        handleConfirmation={handleConfirmation}
        title={`You are about to delete (${deleteAssetCount ?? 1}) assets`}
      />
      <AlertMessage
        message={messageData?.message}
        severity={messageData?.status}
        open={messageData}
        close={() => setMessageData(null)}
      />
    </DataGridStyled>
  );
};

export default DataGrid;
