/* eslint-disable brace-style */
/* eslint-disable quotes */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react'
import {Box, Typography} from '@mui/material'
import {kaReducer} from 'ka-table'
import 'jspdf-autotable'
import {useDispatch, useSelector} from 'react-redux'
import {showLoading} from 'ka-table/actionCreators'
import {NavLink, useParams} from 'react-router-dom/cjs/react-router-dom.min'
import {decode as base64Decode} from 'base-64'
import {useHistory} from 'react-router-dom/cjs/react-router-dom'
import {AlertMessage, CustomButton, CustomTable, CustomTextBox, Loader} from '../../../components'
import {getProjectDataAction, getReportsListAction} from '../../../redux/reports/ReportActions'
import {getAppraiserTrueAction} from '../../../redux/admin/AdminActions'
import {AreaOrderingDialog} from '../components'
import {reportListActions} from '../../../redux/reports/report'
import {getFASReport, getReportAssets, reOrderLocations} from '../../../services/reportServices'
import {getRefDataCommon} from '../../../utils/Report.utils'
import {finalReportTablePropsInit} from '../../../constants/statics'
import CustomReports from '../components/CustomReports/CustomReports'
import {getAssetListDataAction} from '../../../redux/assets/AssetsActions'
import {convertTo24HourFormat, getFormattedDateTime} from '../../../utils/Utils'
import MyDiv from './finalAssetListReport.style'

export default function FinalAssetListReport() {

  const dispatch = useDispatch()

  const {id} = useParams()

  const history = useHistory()


  const [finalListData, setFinalListData] = useState([])
  const [dateTimeValue, setDateTimeValue] = useState(getFormattedDateTime())
  const getSelectedRows = () => {
    const arr = []
    finalListData?.map((i) => {
      arr.push(i.location_id)
    })
    return arr
  }
  const tablePropsInit = {...finalReportTablePropsInit, data: finalListData, selectedRows: getSelectedRows()}
  const [locId, setLocId] = useState(null)
  const [finalTableList, setFinalTableList] = useState(tablePropsInit)
  const [openOrder, setOpenOrder] = useState(false)
  const [expandMore, setExpandMore] = useState(false)
  const [checked, setChecked] = useState([])
  const [filterListData, setFilterDataList] = useState([])
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [projectData, setProjectData] = useState([])
  const [startPage, setStartPage] = useState(1)
  const [formatData, setFormatData] = useState([])
  const [refData, setRefData] = useState(1)
  const [valuesTotal, setValuesTotal] = useState(1)
  const [show, setShow] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isExpand, setExpand] = useState(false)
  const [isAllAppraiserSelected, setAllAppraiserSelected] = useState(false)
  const [selectAppraiser, setSelectAppraiser] = useState(null)

  const reportState = useSelector((state) => state.report)
  const homeState = useSelector((state) => state.home)

  let projectID = localStorage.getItem('project_id') // this is integer project id
  let projectIDCrm = localStorage.getItem('project_id_crm')
  let decodedProjectId = base64Decode(projectIDCrm) // This is a Base64 encoded string with crm id

  let reportListData = {
    pageReport: 'reports',
    project_id_crm: decodedProjectId,
  }

  let projectByIdData = {
    project_id_crm: decodedProjectId,
  }

  const appraiserTrue = {
    getAppraisersOnly: true,
    project_id_crm: decodedProjectId,
  }

  useEffect(() => {
    dispatch(getAppraiserTrueAction(appraiserTrue))
    dispatch(getReportsListAction(reportListData))
    dispatch(getProjectDataAction(projectByIdData))
  }, [dispatch])

  useEffect(() => {
    setFinalListData(reportState?.reportsList?.reportsList)
  }, [reportState?.reportsList?.reportsList])

  useEffect(() => {
    setProjectData(reportState?.projectDataList?.projectDataList?.data)
  }, [reportState?.projectDataList?.projectDataList])


  useEffect(() => {
    setFinalTableList(tablePropsInit)
  }, [finalListData])

  useEffect(() => {
    setAllAppraiserSelected(true)
    let data = finalListData?.filter((item) => {return item.location_id === locId})
    setFilterDataList(data)
  }, [finalListData])

  const dispatchReports = (action) => {
    setFinalTableList((prevState) => kaReducer(prevState, action))
  }
  const handleOrder = (locationId) => {
    setOpenOrder(!openOrder)
    setLocId(locationId)
    let data = finalListData?.filter((item) => {return item.location_id === locationId})
    setFilterDataList(data)
  }
  // console.log('finalTableList?.data', finalListData)
  const handleExpandClick = (e, locationId) => {
    setExpand(!isExpand)
    setExpandMore(e?.currentTarget)
    let filterData = finalListData?.find((item) => {
      if (item?.location_id === locationId) {
        return {...item}
      }
    })
    const tempList = filterData?.appraiser.map(({user_id}) => {
      return user_id
    })
    if (isAllAppraiserSelected) {
      setChecked((prevState) => ({...prevState, [locationId]: tempList}))
    } else {
      const finalObj = checked[locationId]
      if (finalObj?.length !== tempList?.length && checked[locationId] !== undefined) {
        setChecked((prevState) => ({...prevState, [locationId]: finalObj}))
      }
      // else if (!previouslyLocationIdEdit && finalObj?.length === 0) {
      // setChecked((prevState) => ({...prevState, [locationId]: finalObj}))
      // }
      else {
        setChecked((prevState) => ({...prevState, [locationId]: tempList}))
      }
    }
    setLocId(locationId)
    setFilterDataList(filterData?.appraiser)
  }

  const handleExpandClose = () => {
    setExpandMore(false)
    setExpand(!isExpand)
  }

  const handleSelect = () => {
    setShow(!show)
  }

  useEffect(() => {
    const dataArray = {}
    let updateState = true // ? will update in phase 2 if needed but will do as is for now
    finalTableList?.data?.forEach(({location_id, appraiser}) => {
      selectAppraiser[location_id] ? updateState = false : updateState = true
      dataArray[location_id] = appraiser
    })
    updateState ? setSelectAppraiser(dataArray) : null
  }, [finalTableList])

  const handleChange = (event, itemID) => {
    setAllAppraiserSelected(false)
    const isChecked = event.target.checked

    setChecked((prevChecked) => {
      const locChecked = prevChecked[locId] || []
      let updatedCheck
      if (isChecked) {
        updatedCheck = [...locChecked, itemID]
      } else {
        updatedCheck = locChecked.filter((id) => id !== itemID)
      }
      return {...prevChecked, [locId]: updatedCheck}
    })

    setSelectAppraiser((prevSelectAppraiser) => {
      const locAppraisers = prevSelectAppraiser[locId] || []
      let updatedAppraisers

      if (isChecked) {
        if (!locAppraisers.some((obj) => obj.user_id === itemID)) {
          const fullLocationAppraiserData = finalListData?.find((item) => item.location_id === locId)
          const currentAppraiser = fullLocationAppraiserData?.appraiser?.find((item) => item.user_id === itemID)

          updatedAppraisers = [...locAppraisers, currentAppraiser]
        } else {
          updatedAppraisers = locAppraisers
        }
      } else {
        updatedAppraisers = locAppraisers.filter((obj) => obj.user_id !== itemID)
      }

      return {...prevSelectAppraiser, [locId]: updatedAppraisers}
    })
  }

  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
    dispatch(reportListActions.clearMessageData())
  }
  const calculateLabel = (location) => {
    let locationLabel = ''
    if (location.street && location.street !== '') {
      locationLabel = location.street
    }

    if (location.city && location.city !== 'undefined') {
      if (locationLabel === '') {
        locationLabel = location.city
      } else if (locationLabel === undefined) {
        locationLabel += `,${location.city}`
      }
    }

    if (location.state && location.state !== '') {
      if (locationLabel === '') {
        locationLabel = location.state
      } else {
        locationLabel += `,${location.state}`
      }
    }
    return locationLabel
  }

  const computeFinalReportDataForApi = () => {
    const locationData = [...finalListData]
    // ! breaks on local and why is this variable even created if it is never used?
    // const appraiserData = [...selectAppraiser[locId]] // change this because of appraiser based on location id
    const finalProjectDataFull = locationData.map((data, index) => {
      const updatedData = {...data}
      updatedData.elected = finalTableList?.selectedRows?.includes(data?.location_id)
      updatedData.location_label = calculateLabel(data)
      updatedData.selectedAppraisers = selectAppraiser[data?.location_id]
      return updatedData
    })
    return finalProjectDataFull
  }
  //genearateFASinOne in the angular project
  const generateFASinOne = (fData) => {
    // todo - set loading state
    // $scope.LocationIdString()

    const fasOneTabArgument = {}
    fasOneTabArgument.project_id = decodedProjectId
    fasOneTabArgument.reportType = 'fas_excel_list'
    fasOneTabArgument.reportFormat = 'excel'

    // $scope.LocationIdString()
    let formattedData
    if (finalTableList?.selectedRows?.length > 0) {
      formattedData = fData?.filter((value) => {
        if (value.area_label !== undefined) {
          return true
        }
        if (finalTableList?.selectedRows.includes(value.location_id)) {
          return true
        }
        return false
      })
    }
    fasOneTabArgument.reportData = formattedData
    getFASReport(fasOneTabArgument).then((res) => {
      setIsLoading(false)
      let path = res.data.path
      window.open(path)
    }).catch(() => {
      setMessage('Something went wrong')
      setStatus('error')
      setIsLoading(false)
    })
  }

  const jaoMaroKahiPSaatMai = () => {
    reportState?.reportsList?.reportsList?.forEach((currentReport) => {
      currentReport.selectedAppraisers = selectAppraiser?.[currentReport?.location_id]
    })
  }
  //genearateFASinCustomLocation in angular
  const generateFASinCustomLocation = (fData) => {
    const finalProjectDataFull = computeFinalReportDataForApi()
    const fasCustomArgument = {}
    fasCustomArgument.project_id = decodedProjectId
    if (finalTableList?.selectedRows?.length > 0) {
      const locationDataFull = finalProjectDataFull.map(({location_id}) => {
        const currentReport = reportState?.reportsList?.reportsList?.find((report) => report.location_id === location_id)
        return {
          ...currentReport,
          // elected: !!finalListData?.selectedRows?.includes(location_id.toString()),
          selectedAppraisers: selectAppraiser[location_id],
        }
      })
      // let locationIdArray = reportState?.selectedItems.join(',')
      fasCustomArgument.locationArray = finalTableList?.selectedRows.join(',').toString()
      fasCustomArgument.locationDataFull = locationDataFull
      // fasCustomArgument.locationDataFull = reportState?.reportsList?.reportsList
      fasCustomArgument.reportType = 'fas_excel_list_custom'
      fasCustomArgument.reportFormat = 'excel'
      fasCustomArgument.reportData = fData
    }
    getFASReport(fasCustomArgument).then((res) => {
      setIsLoading(false)
      let path = res.data.path
      window.open(path)
    }).catch(() => {
      setIsLoading(false)
      setMessage('Something went wrong')
      setStatus('error')
    })
  }
  const generatePdfList = (type, reportType, fData, vTotal, format, rData, hideCompany = null) => {
    const finalProjectDataFull = computeFinalReportDataForApi()
    let currencySymbol = '$'
    if (type === 'custom_asset_list' || finalTableList?.selectedRows?.length > 0) {
      const pdfArgument = {}
      pdfArgument.project_id = +projectID
      pdfArgument.reportType = reportType
      pdfArgument.reportFormat = format
      pdfArgument.values_total = vTotal
      pdfArgument.currency_symbol = currencySymbol
      pdfArgument.locationArray = finalTableList?.selectedRows
      pdfArgument.locationDataFull = finalProjectDataFull
      pdfArgument.reportData = fData
      pdfArgument.refData = rData
      pdfArgument.projectData = projectData[0]
      pdfArgument.start_page = startPage || null
      pdfArgument.reportDateTime = convertTo24HourFormat(dateTimeValue)

      if (hideCompany && hideCompany === 'pv') {
        pdfArgument.hidePriceValues = true
      } else if (hideCompany && hideCompany === 'c') {
        pdfArgument.hidecompanyOnly = true
      } else if (hideCompany && hideCompany === 'pvc') {
        pdfArgument.hideCompany = true
      }
      getFASReport(pdfArgument).then((res) => {
        if (res.data.msg) {
          setMessage('No asset found')
          setStatus('info')
          setIsLoading(false)
          return
        }
        if (res.data?.path) {
          let path = `${process.env.REACT_APP_BASE_URL}storage/reports/${res.data.path}`
          window.open(path)
          setIsLoading(false)
        }
      }).catch((error) => {
        console.log('error', error)
        setIsLoading(false)
        setMessage('Something went wrong')
        setStatus('error')
      })
    }
  }
  const generateFinalExcel = (fData) => {
    const finalProjectDataFull = computeFinalReportDataForApi()
    const finalAssetArgument = {}
    finalAssetArgument.project_id = decodedProjectId
    if (finalTableList?.selectedRows?.length > 0) {
      const locationIdArray = finalTableList?.selectedRows.join(',').toString()
      finalAssetArgument.locationArray = locationIdArray
      finalAssetArgument.locationDataFull = finalProjectDataFull
      finalAssetArgument.reportType = 'final_asset_list'
      finalAssetArgument.reportFormat = 'excel'
      finalAssetArgument.reportData = fData

      getFASReport(finalAssetArgument).then((res) => {
        setIsLoading(false)
        let path = res.data.path
        window.open(path)
      }).catch(() => {
        setMessage('Something went wrong')
        setStatus('error')
        setIsLoading(false)
      })
    }
  }
  const dataForSelectedLocations = (type, listType, format, hideCompany, isCustom) => () => {
    setIsLoading(true)
    const filterOptions = {
      sortCol: 'area_order',
      sortOrder: 'asc',
      action: 'getAssets',
      showOnlyAsset: false,
      appraiser: null,
      make: null,
      model: null,
      capacity: null,
      area: null,
      projectId: finalListData[0]?.project_id,
      locations: finalListData?.selectedRows ?? null,
      getRefDataForReports: true,
    }
    if (!finalTableList?.selectedRows?.length > 0) {
      setIsLoading(false)
      setMessage('Please select at least one location.')
      setStatus('warning')
      return
    }
    filterOptions.locations = finalTableList?.selectedRows
    getReportAssets(filterOptions).then((responseData) => {
      const formattedData = getRefDataCommon(responseData, filterOptions)

      // const reportData = formattedData.reportData

      setFormatData(formattedData.reportData)

      setRefData(responseData.refData)

      setValuesTotal(formattedData.values_total)

      switch (type) {
        case 'generateFASExcelReportsInOneTab':
          generateFASinOne(formattedData.reportData)
          break
        case 'generateFASExcelReport':
          generateFASinCustomLocation(formattedData.reportData)
          break
        case 'handlePdf':
          generatePdfList(type, listType, formattedData.reportData, formattedData.values_total, format, responseData.refData, hideCompany)
          break
        case 'generateReviewReportPdf':
          generatePdfList(type, listType, formattedData.reportData, formattedData.values_total, format, responseData.refData, hideCompany)
          break
        case 'generateFinalExcel':
          generateFinalExcel(formattedData.reportData)
          break
        default:
          break
      }
    }).catch(() => {
      setMessage('Something went wrong')
      setStatus('error')
      setIsLoading(false)
    })

  }

  const generateReport = (data, linesData, reportType, reportFormat, showCompany, type) => {

    if (type === 'excel') {
      const objData = {
        project_id: decodedProjectId,
        reportType: reportType,
        reportFormat: reportFormat,
        lineData: linesData,
        reportData: data,
      }
      // ! waiting for confirmation from Munna
      objData.reportData.forEach((element) => {
        element.ref_no = element.ref
        // delete element.ref
      })
      setIsLoading(true)
      getFASReport(objData).then((res) => {
        if (res.data.msg) {
          setMessage('No asset found')
          setStatus('info')
          setIsLoading(false)
          return
        }
        setIsLoading(false)
        // let path = `${process.env.REACT_APP_BASE_URL}storage/reports/${res.data.path}`
        window.open(res.data.path)
      }).catch(() => {
        setIsLoading(false)
        setMessage('Something went wrong')
        setStatus('error')
      })
      return
    }
    const objData = {
      currencySymbol: '$',
      lineData: linesData,
      locationArray: [],
      locationDataFull: reportState?.reportsList?.reportsList,
      projectData: reportState?.projectDataList?.projectDataList?.data[0],
      project_id: projectID,
      refData,
      reportData: data,
      reportFormat: reportFormat,
      reportType: reportType,
      start_page: startPage,
      reportDateTime: dateTimeValue,
      values_total: valuesTotal,
    }

    if (showCompany && showCompany === 'pv') {
      objData.hidePriceValues = true
    }
    if (showCompany && showCompany === 'c') {
      objData.hidecompanyOnly = true
    }
    if (showCompany && showCompany === 'pvc') {
      objData.hideCompany = true
    }
    setIsLoading(true)

    getFASReport(objData)
      .then((res) => {
        if (res.data.msg) {
          setMessage('No asset found')
          setStatus('info')
          setIsLoading(false)
          return
        }

        setIsLoading(false)
        let path = `${process.env.REACT_APP_BASE_URL}storage/reports/${res.data.path}`
        window.open(path)
      })
      .catch(() => {
        setIsLoading(false)
        setMessage('Something went wrong')
        setStatus('error')
      })
  }

  const handleCustomReports = (data, linesData, type, reportType, reportFormat, showCompany) => {
    if (data?.length === 0) {
      setIsLoading(false)
      setMessage('Please select at least one asset.')
      setStatus('warning')
      return
    }

    switch (type) {
      case 'withoutPrice':
        generateReport(data, linesData, reportType, reportFormat, showCompany, 'withoutPrice')
        break
      case 'withoutCompany':
        generateReport(data, linesData, reportType, reportFormat, showCompany, 'withoutCompany')
        break
      case 'withoutPriceAndCompany':
        generateReport(data, linesData, reportType, reportFormat, showCompany, 'withoutPriceAndCompany')
        break
      case 'general':
        generateReport(data, linesData, reportType, reportFormat, showCompany, 'general')
        break
      case 'excel':
        generateReport(data, linesData, reportType, reportFormat, showCompany, 'excel')
        break
      default:
        break
    }
  }

  const handleLocationCustomReport = (location) => {
    history.push(`/asset-list-reports/${location}`)
  }

  useEffect(() => {
    // was not required as per the initial development but since we need the
    //formatData, refData, valuesTotal states and the location values are in the global state
    // I am now getting that data on initial load incase we have the param id
    if (id && finalListData && finalListData?.length > 0) {
      // dataForSelectedLocations('test')
      setIsLoading(false)
      const filterOptions = {
        sortCol: 'area_order',
        sortOrder: 'asc',
        action: 'getAssets',
        showOnlyAsset: false,
        appraiser: null,
        make: null,
        model: null,
        capacity: null,
        area: null,
        projectId: +finalListData[0]?.project_id,
        // eslint-disable-next-line eqeqeq
        locationId: id == -1 ? '' : id,
        getRefDataForReports: true,
      }
      getReportAssets(filterOptions).then((responseData) => {
        const formattedData = getRefDataCommon(responseData, filterOptions)

        setFormatData(formattedData.reportData)

        setRefData(responseData.refData)

        setValuesTotal(formattedData.values_total)

        setIsLoading(false)
      }).catch((error) => {
        setIsLoading(false)
        setMessage('Something went wrong', error)
        setStatus('error')
      })
    }
  }, [id, finalListData])


  const handleLocationReordering = (newLocationOrder) => {
    setIsLoading(true)
    reOrderLocations({
      reOrderLocation: 'LocationOrder',
      locaionIds: newLocationOrder.join(','),
      id: 2,
    }).then(() => {
      dispatch(getAssetListDataAction({action: 'getLocation', project_id: projectID}))
      setIsLoading(false)
    })
  }
  return (
    <MyDiv>
      {(reportState?.reportsList?.loading || homeState?.locationsPriceValues?.loading || isLoading) &&
      <div>
        <Loader />
      </div>
      }
      {id ?
        <>
          <CustomReports handleCustomReports={(data, linesData, type, reportType, reportFormat, showCompany) => handleCustomReports(data, linesData, type, reportType, reportFormat, showCompany)}
            handleSelect={handleSelect} showIcon={show}
          />
          <AlertMessage
            message={message}
            severity={status}
            open={message?.length > 0}
            close={handleSnackBarClose}
          />
        </>
        :
        <>
          <Box className="page_heading">
            <Typography>FINAL ASSET LIST REPORT</Typography>
          </Box>
          <Box className="button_group">
            <CustomButton
              title="FAS Excel Report"
              variant="contained"
              className="btn_theme"
              onClick={dataForSelectedLocations('generateFASExcelReport')}
            />
            <CustomButton
              title="PDF Report"
              variant="contained"
              className="btn_theme"
              onClick={dataForSelectedLocations('handlePdf', 'final_asset_list', 'pdf')}
            />
            <CustomButton
              title="Excel Report"
              variant="contained"
              className="btn_theme"
              onClick={dataForSelectedLocations('generateFinalExcel')}
            />
            <CustomButton
              title="PDF Review Report"
              variant="contained"
              className="btn_theme"
              onClick={dataForSelectedLocations('generateReviewReportPdf', 'review_asset_list', 'pdf')}
            />
            <CustomButton
              title="FAS Excel Reports in one tab"
              variant="contained"
              onClick={dataForSelectedLocations('generateFASExcelReportsInOneTab')}
              className="btn_theme"
            />
            <NavLink to={'/asset-list-reports/-1'}>Custom Reports</NavLink>
            <Box className="input_box">
              <Typography>Date</Typography>
              <input aria-label="Date" type="date" style={{padding: '5px'}} value={dateTimeValue} onChange={(e) => setDateTimeValue(e.target.value)} />
            </Box>
            <Box className="input_box">
              <Typography>Starting Page</Typography>
              <CustomTextBox type="number" maxLength="5" name="model" value={startPage} onChange={(e) => setStartPage(e.target.value)} />
            </Box>
          </Box>
          <CustomTable
            tableProps={finalTableList}
            dispatch={dispatchReports}
            handleOrder={handleOrder}
            expandMore={expandMore}
            handleExpandClick={handleExpandClick}
            handleExpandClose={handleExpandClose}
            filterListData={filterListData}
            handleChange={handleChange}
            handleLocationCustomReport={handleLocationCustomReport}
            checked={checked}
            isExpand={isExpand}
            locId={locId}
            handleLocationReordering={handleLocationReordering}
          />
          <AreaOrderingDialog
            openOrder={openOrder}
            handleOrder={handleOrder}
            areaList={filterListData}
          />
          <AlertMessage
            message={message}
            severity={status}
            open={message?.length > 0}
            close={handleSnackBarClose}
          />
        </>
      }

    </MyDiv>
  )
}
