/* eslint-disable max-len */
/* eslint-disable no-empty */
import apiInstance from '../config/api/axios'

const fetchAssetList = async(inputdata) => {

  // eslint-disable-next-line no-unused-vars
  let projectID = localStorage.getItem('project_id')

  let payload = {
    NForDeleted: inputdata?.NForDeleted ?? false,
    action: 'getAssets',
    appraiser: inputdata?.appraiser ?? null,
    area: inputdata?.area ?? null,
    capacity: null,
    global_search: inputdata?.search ?? null,
    hideCategorized: inputdata?.hideCategorized ?? false,
    isAudio: inputdata?.isAudio?.toString() ?? null,
    location: null,
    locationId:
      (inputdata?.location?.toString() || inputdata?.location_id?.toString()) ??
      null,
    make: null,
    model: null,
    pageNumber: 1,
    pageSize: 60,
    projectId: projectID,
    showOnlyAsset: inputdata?.showOnlyAsset ?? false,
    sortCol: 'area_order',
    sortOrder: 'asc',
  }

  try {
    const response = await apiInstance.post('asset-list', payload)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const fetchAssetListForReports = async(locationId) => {
  let projectID = localStorage.getItem('project_id')
  const payload = {
    sortCol: 'area_order',
    sortOrder: 'asc',
    action: 'getAssets',
    showOnlyAsset: false,
    appraiser: null,
    make: null,
    model: null,
    capacity: null,
    area: null,
    projectId: projectID,
    locationId,
    getRefDataForReports: true,
  }
  try {
    const response = await apiInstance.post('asset-list', payload)
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}
const fetchAssetListForValuation = async(payload) => {
  try {
    const response = await apiInstance.post('asset-list', payload)
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

const getAssetList = async(payload) => {
  try {
    const response = await apiInstance.post('asset-list', payload)
    if (payload.action === 'getLocation') {
      return response.data
    }
    if (payload.action === 'getAppraiser') {
      return response.data
    }
    if (payload.action === 'getArea') {
      return response.data
    }
    if (payload.action === 'insertNewArea') {
      return response.data
    }
    if (payload.action === 'insertNewAsset') {
      return response.data
    }
    if (payload.action === 'reassignAppraiserToAsset') {
      return response.data
    }
    if (payload.action === 'changeAreaName') {
      return response
    }
    if (payload.flag === 'valuationReports') {
      return response.data
    }
  } catch (error) {
    console.log(error)
  }
}

const fetchAssetDetails = async(payload) => {
  try {
    const response = await apiInstance.get(`asset-detail?project_id_crm=${payload.project_id_crm}&type=${payload.type}`)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const postFlagged = async(payload) => {
  try {
    const response = await apiInstance.put('asset-list/flag', payload)
    return response.data
  } catch (error) {
  }
}

const fetchFindReplace = async(payload) => {
  try {
    if (payload?.importedAsset) {
      const response = await apiInstance.get(`find-replace?find=${payload.find}&importedAsset=${payload.importedAsset}&matchtype=${payload.matchtype}&project_id_crm=${payload.project_id_crm}&replace=${payload.replace}&type=${payload.type}`, payload)
      return response.data?.assets
    } else {
      const response = await apiInstance.get(`find-replace?find=${payload.find}&matchtype=${payload.matchtype}&project_id_crm=${payload.project_id_crm}&replace=${payload.replace}&type=${payload.type}`, payload)
      return response.data?.assets
    }
  } catch (error) {
  }
}

const postFindReplace = async(payload) => {
  try {
    const response = await apiInstance.post('find-replace', payload)
    return response
  } catch (error) {
    console.log('error', error)
  }
}

const postAddAssets = async(payload) => {
  try {
    const response = await apiInstance.post('asset-detail', payload)
    return response
  } catch (error) {
  }
}
const deleteAssets = async(payload) => {
  try {
    const response = await apiInstance.delete(`asset-detail?delete_ids=${payload}`)
    return response
  } catch (error) {
  }
}
const copyAssetBelow = async(payload) => {
  try {
    const response = await apiInstance.put('/asset-list/copyAsset', payload)
    return response
  } catch (error) {
  }
}
const insertAssetAbove = async(payload) => {
  try {
    const response = await apiInstance.post('asset-detail', payload)
    return response
  } catch (error) {
  }
}
const insertAssetBelow = async(payload) => {
  try {
    const response = await apiInstance.post('asset-detail', payload)
    return response
  } catch (error) {
  }
}
const blockMoveAsset = async(payload) => {
  try {
    const response = await apiInstance.put('/asset-list/moveAssets', payload)
    return response
  } catch (error) {
  }
}
const postUpdateAssets = async(payload) => {
  try {
    const response = await apiInstance.post('asset-detail', payload)
    return response
  } catch (error) {
  }
}
const saveAndCreateNew = async(payload) => {
  try {
    const response = await apiInstance.post('asset-detail', payload)
    return response
  } catch (error) {
  }
}
const saveAndCopyNew = async(payload) => {
  try {
    const response = await apiInstance.post('asset-detail', payload)
    return response
  } catch (error) {
  }
}
const saveAndCopyBelow = async(payload) => {
  try {
    const response = await apiInstance.post('asset-detail', payload)
    return response
  } catch (error) {
  }
}

const fetchAssetsByAppraiser = async(payload) => {
  try {
    if (payload.min || payload.max || payload.asset_id) {
      const response = await apiInstance.get(`assets-by-appraiser?asset_id=${payload.asset_id}&location_id=${payload.location_id}&max=${payload.max}&min=${payload.min}&project_id_crm=${payload.project_id_crm}&user_id=${payload.user_id}`)
      return response?.data?.assets
    } else {
      const response = await apiInstance.get(`assets-by-appraiser?asset_id=${payload.asset_id}&location_id=${payload.location_id}&project_id_crm=${payload.project_id_crm}&user_id=${payload.user_id}`)
      return response?.data?.assets
    }
  } catch (error) {
  }
}

const fetchAssetPhotos = async(payload) => {
  try {
    const response = await apiInstance.get(`photos-by-asset?project_id_crm=${payload.project_id_crm}`)
    return response.data
  } catch (error) {
  }
}
const fetchPhotosByAssetId = async(type, typeId) => {
  try {
    const response = await apiInstance.get(`photos-by-asset?type=${type}&type_id=${typeId}`)
    return response.data
  } catch (error) {
    throw error
  }
}

const fetchPhotosByAppraiser = async(payload) => {
  try {
    const response = await apiInstance.get(`photos-by-appraiser?project_id_crm=${payload.project_id_crm}&user_id=${payload.user_id}`)
    return response?.data?.photos
  } catch (error) {
    console.log('error', error)
  }
}

const postPhotosToAssets = async(payload) => {
  try {
    const response = await apiInstance.post('map-photos-to-asset', payload)
    return response
  } catch (error) {
  }
}

const deletePhotosToAssets = async(payload) => {
  try {
    const response = await apiInstance.delete(`map-photos-to-asset?photo_location=${payload.photo_location}&type=${payload.type}&type_id=${payload.type_id}`, payload)
    return response
  } catch (error) {
  }
}
const fetchModelByMake = async() => {
  try {
    const response = await apiInstance.get('make-model-list')
    return response?.data
  } catch (error) {
  }
}

const assetDrop = async(payload) => {
  try {
    const response = await apiInstance.put('asset-list/multiAssetDrop', payload)
    return response
  } catch (error) {
  }

}

const fetchAssetClass = async() => {
  try {
    const response = await apiInstance.get('asset-list/getClass')
    return response.data
  } catch (error) {
  }
}

const fetchCondition = async() => {
  try {
    const response = await apiInstance.get('asset-conditions')
    return response.data
  } catch (error) {
  }
}

const updateAssetClass = async(payload) => {
  try {
    const response = await apiInstance.put('asset-list/inline', payload)
    return response
  } catch (error) {
    throw error
  }
}
const deleteInnerAssets = async(payload) => {
  try {
    const response = await apiInstance.delete(`asset-detail/${payload}`)
    return response
  } catch (error) {
  }
}

const getAssetDetails = async(payload) => {
  try {
    const {data} = await apiInstance.get(`asset-detail?id=${payload.id}&location_id=${payload.location_id}&project_id_crm=${payload.project_id_crm}`)
    return data
  } catch (error) {
    throw error
  }
}

const getAssetInEdit = async(payload) => {
  try {
    const res = await apiInstance.post('asset-list?action=getAssetInEdit', payload)
    return res?.data
  } catch (error) {
    throw error
  }
}
export {fetchAssetList,
  blockMoveAsset, deleteInnerAssets, saveAndCopyBelow, saveAndCopyNew, saveAndCreateNew, insertAssetAbove, insertAssetBelow, copyAssetBelow, deleteAssets, postFlagged, postUpdateAssets, fetchAssetDetails, fetchFindReplace, postFindReplace, postAddAssets, fetchAssetsByAppraiser, fetchAssetPhotos, fetchPhotosByAppraiser,
  postPhotosToAssets, deletePhotosToAssets, getAssetList, fetchModelByMake, assetDrop, fetchAssetClass, updateAssetClass, fetchCondition, getAssetDetails, fetchPhotosByAssetId, getAssetInEdit, fetchAssetListForReports, fetchAssetListForValuation}

