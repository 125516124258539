/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  Select,
  Typography,
  MenuItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import styled from 'styled-components';
import { decode as base64Decode } from 'base-64';
import { useDispatch, useSelector } from 'react-redux';
import {
  CustomButton,
  CustomTextBox,
  DataGrid,
} from '../../../components';
import { tableConfig } from '../../../utils/Table';
import { ColumnConfig } from '../../../utils/Column';
import { audioData } from '../../../constants/constant';
import MyDiv from './assetList.style';
import {
  fetchProjectData,
  getProjectDataByType,
} from '../../../services/apiService';
import { createUniqueAreasFromDuplicates, createUniqueUsernamesFromDuplicates, getFromLocalStorage, setLocalStorageItem } from '../../../utils/Utils';

const StyledMenuItem = styled(MenuItem)(() => ({
  '&.MuiMenuItem-root': {
    borderBottom: '1px solid #ccc',
    color: '#000000',
    fontWeight: '400',
    fontSize: '14px',
    fontFamily: 'Arial',
  },
  '&.MuiMenuItem-root:first-child': {
    marginTop: '-8px',
  },
  '&.MuiMenuItem-root:last-child': {
    marginBottom: '-8px',
  },
}));

const ITEM_HEIGHT = 60;
const Menu = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5,
    },
  },
};

export default function AssetList() {
  const dispatch = useDispatch();

  //! my new state variables
  const [myProjectData, setMyProjectData] = useState({});
  const [inputValues, setInputValues] = useState({
    location: 0,
    appraiser: 0,
    area: 0,
  });
  const [fetchAssetsInChild, setFetchAssetsInChild] = useState(0)
  console.log('🚀 ~ AssetList ~ myProjectData:', myProjectData);
  //! my new state variables
  const [config, setConfig] = useState(tableConfig);
  const [columnConfig, setColumnConfig] = useState(ColumnConfig);
  const [columnSetting, setColumnSetting] = useState(false);
  console.log('🚀 ~ AssetList ~ inputValues:', inputValues);
  const [assetListData, setAssetListData] = useState([]);
  const [locationId, setLocationId] = useState(null);
  const [showRefAndAssetInputs, setShowRefAndAssetInputs] = useState(true);
  const [filterAssetInDataGrid, setFilterAssetInDataGrid] = useState(false);
  // const [isGlobalSearch, setIsGlobalSearch] = useState(false);
  const [ref, setRef] = useState('');
  const [asset_id, setAsset_id] = useState('');

  const resetInputValuesState = () => {
    setInputValues({ location: 0, appraiser: 0, area: 0 });
  };
  const { general: generalState } = useSelector((state) => state);

  let projectID = localStorage.getItem('project_id');
  let locationIDHome = localStorage.getItem('location_id_home');
  let projectIDCrm = localStorage.getItem('project_id_crm');
  let decodedProjectId = base64Decode(projectIDCrm); // This is a Base64 encoded string

  //! updated - we get the initial data required in this page, the locations, areas and appraisers for the project (dumbing down for when I forget things)
  const fetchProjectDataOrWhateverWeNameItLater = async () => {
    let [locations, appraisers, areas, projectData] = await Promise.all([
      getProjectDataByType({ action: 'getLocation', project_id: projectID }),
      getProjectDataByType({ action: 'getAppraiser', project_id: projectID }),
      getProjectDataByType({ action: 'getArea', project_id: projectID }),
      fetchProjectData(decodedProjectId),
    ]);
    appraisers = createUniqueUsernamesFromDuplicates(appraisers);
    areas = createUniqueAreasFromDuplicates(areas);
    setMyProjectData({
      locations,
      appraisers,
      areas,
      projectData,
      filteredData: {
        appraisers,
        areas,
      },
    });
  };

  //! updated - call the fetchProjectDataOrWhateverWeNameItLater function and also set the initial state based on localStorage data
  useEffect(() => {
    const location = getFromLocalStorage(
      'location_id_home',
      inputValues?.location || 0
    );
    const appraiser = parseInt(
      getFromLocalStorage('selectedAppraiser', inputValues?.appraiser || 0),
      10
    );
    const area = parseInt(
      getFromLocalStorage('selectedArea', inputValues?.area || 0),
      10
    );
    setInputValues({ location, appraiser, area });
    const search = getFromLocalStorage('global_search_string', '');
    fetchProjectDataOrWhateverWeNameItLater();
  }, []);
  // ! updated - single change handler with a single state rather than different handlers and multiple states.
  const handleChange = (e, type) => {
    const { name, value, checked } = e.target;
    switch (type) {
      case 'hide assets':
        setInputValues((prevState) => ({ ...prevState, [name]: checked }));
        setFetchAssetsInChild((prevState) => prevState +1)
        break;
      case 'ref':
        setInputValues((prevState) => ({
          ...prevState,
          [name]: value,
          asset_id: '',
          search: '',
        }));
        break;
      case 'asset_id':
        setInputValues((prevState) => ({
          ...prevState,
          [name]: value,
          ref: '',
          search: '',
        }));
        break;
      case 'search':
        setInputValues((prevState) => ({
          ...prevState,
          [name]: value,
          ref: '',
          asset_id: '',
        }));
        break;
      case 'show assets':
        setInputValues((prevState) => ({ ...prevState, [name]: checked }));
        setFetchAssetsInChild((prevState) => prevState +1)
        break;
      case 'delete':
        setInputValues((prevState) => ({ ...prevState, [name]: checked }));
        setFetchAssetsInChild((prevState) => prevState +1)
        break;
      case 'location':
        setInputValues((prevState) => ({
          ...prevState,
          [name]: value,
          area: 0,
          appraiser: 0,
        }));
        // todo - figure any changes out
        let appraisers = myProjectData.appraisers;
        let areas = myProjectData.areas;
        if (value > 0) {
          appraisers = myProjectData.appraisers.filter(
            ({ location_id }) => +location_id === +value
          );
          areas = myProjectData.areas.filter(
            ({ location_id }) => +location_id === +value
          );
        }
        setMyProjectData((prevState) => ({
          ...prevState,
          filteredData: { ...prevState.filteredData, appraisers, areas },
        }));
            if (value > 0 && locationIDHome) {
      setLocalStorageItem('manually_updated_location', 1);
    }
    if (value > 0) {
      setLocalStorageItem('manually_updated_location', 1);
      setLocalStorageItem('location_id_home', value);
    } else {
      removeLocalStorageItem('manually_updated_location');
      removeLocalStorageItem('location_id_home');
    }
        setFetchAssetsInChild((prevState) => prevState +1)
        break;
      default:
        setInputValues((prevState) => ({ ...prevState, [name]: value }));
        if(name == 'area') {
          setLocalStorageItem('selectedArea', value);
        } else if (name == 'appraiser') {
          setLocalStorageItem('selectedAppraiser', value > 0 ? value : '');
        }
        setFetchAssetsInChild((prevState) => prevState +1)
        break;
    }
  };

  // ! c-111
  const handleSelect = () => {
    showRefAndAssetInputs(!showRefAndAssetInputs);
  };

  // todo - updated - 
  const handleReset = () => {
    const manuallyUpdated = localStorage.getItem('manually_updated_location');
    localStorage.removeItem('selectedArea'); //! why?
    localStorage.removeItem('selectedAppraiser'); //! why?
    localStorage.removeItem('global_search_string'); //! why?
    // ! not even required outside of clearing the local storage
    if (manuallyUpdated == 1) {
      localStorage.removeItem('location_id_home');
      localStorage.removeItem('manually_updated_location');
      resetInputValuesState();
    } else {
      const location = localStorage.getItem('location_id_home');
      setInputValues({
        location,
        appraiser: '',
        area: '',
        isAudio: '',
        search: '',
        hideCategorized: false,
        NForDeleted: false,
        showOnlyAsset: false,
      });
    }
    window.location.reload();
  };

  let timeoutId;
  //! partly updated
  const handleSearch = () => {
    if (setInputValues?.asset_id || setInputValues?.ref) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setInputValues((prevState) => ({
          ...prevState,
          ref: '',
          asset_id: '',
        }));
      }, 3500);
      setFilterAssetInDataGrid(true);
    } else {
      setInputValues((prevState) => ({
        ...prevState,
        ref: '',
        asset_id: '',
      }));
      inputValues?.search ? localStorage.setItem('global_search_string', inputValues?.search) : null
      // setIsGlobalSearch(true);
      setFetchAssetsInChild((prevState) => prevState +1)
    }
  };
  // ! c-114
  const handleSearchOnEnter = (e) => {
    if (e.keyCode === 13) {
      localStorage.setItem('global_search_string', inputValues?.search);
      setInputValues((prevState) => ({ ...prevState, ref: '', asset_id: '' }));
      // setIsGlobalSearch(true); // ! needed now?
      setFetchAssetsInChild((prevState) => prevState +1)
    }
  };

  // ! c-121
  const handleFindAsset = (e) => {
    if (e.keyCode === 13) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setInputValues((prevState) => ({
          ...prevState,
          asset_id: '',
          ref: '',
        }));
      }, 2000);
      setFilterAssetInDataGrid(true);
    }
  };

  const resetGlobalSearch = () => {
    setInputValues((prevState) => ({...prevState, asset_id: '', ref: ''}))
    // setIsGlobalSearch(false)
  }
  return (
    <MyDiv>
      <Box className="page_heading">
        <Box className="heading_box">
          <Typography>
            Assets List <span>Total Assets : {generalState.assetCount}</span>
          </Typography>
          <FormControl className="checkbox_container">
            <FormControlLabel
              className="checked_label"
              control={
                <Checkbox
                  value={inputValues.hideCategorized}
                  onChange={(e) => {
                    handleChange(e, 'hide assets');
                    // handleCheckbox(e);
                    // dispatch(assetsListActions.setDoFetchAssetList(true));
                  }}
                  checked={inputValues.hideCategorized}
                  name="hideCategorized"
                />
              }
              label="Hide Cat. Assets"
            />
            <FormControlLabel
              className="checked_label"
              control={
                <Checkbox
                  value={inputValues.showOnlyAsset}
                  onChange={(e) => {
                    handleChange(e, 'show assets');
                    // handleCheckbox(e);
                    // dispatch(assetsListActions.setDoFetchAssetList(true));
                  }}
                  checked={inputValues.showOnlyAsset}
                  name="showOnlyAsset"
                />
              }
              label="Show Assets"
            />
            <FormControlLabel
              className="checked_label"
              control={
                <Checkbox
                  value={inputValues.NForDeleted}
                  onChange={(e) => {
                    handleChange(e, 'delete');
                    // handleCheckbox(e);
                    // dispatch(assetsListActions.setDoFetchAssetList(true));
                  }}
                  checked={inputValues.NForDeleted}
                  name="NForDeleted"
                />
              }
              label="NF / Deleted"
            />
          </FormControl>
        </Box>
        <Box className="form_box">
          <FormControl size="small" fullWidth>
            <Select
              id="select_input"
              className="select_value"
              value={inputValues.location} // need to cleanup this as well
              onChange={(e) => handleChange(e, 'location')}
              name="location"
              MenuProps={{ ...Menu }}
            >
              <StyledMenuItem value={0}>All Locations</StyledMenuItem>
              {myProjectData?.locations?.map((item, index) => {
                return (
                  <StyledMenuItem key={index} value={item.location_id}>
                    {item.name} [{item.street} {item.city} {item.country}]
                  </StyledMenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl size="small" fullWidth>
            <Select
              id="select_input"
              className="select_value"
              value={inputValues.appraiser}
              onChange={handleChange}
              name="appraiser"
              MenuProps={{ ...Menu }}
            >
              <StyledMenuItem value={0}>All Appraisers</StyledMenuItem>

              {myProjectData?.filteredData?.appraisers?.map((item, index) => {
                return (
                  <StyledMenuItem key={index} value={item.user_id}>
                    {item.last_name} {item.name}
                  </StyledMenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl size="small" fullWidth>
            <Select
              id="select_input"
              className="select_value"
              value={inputValues.area}
              onChange={handleChange}
              name="area"
              MenuProps={{ ...Menu }}
            >
              <StyledMenuItem value={0}>All Areas</StyledMenuItem>
              {myProjectData?.filteredData?.areas?.map((item, index) => {
                return (
                  <StyledMenuItem key={index} value={item.area}>
                    {item.area}
                  </StyledMenuItem>
                );
              })}
            </Select>
          </FormControl>
          <CustomTextBox
            fieldLabel="Search"
            name="search"
            onKeyDown={handleSearchOnEnter}
            value={inputValues?.search}
            onChange={(e) => handleChange(e, 'search')}
            className="search_input"
          />
          <FormControl size="small" fullWidth>
            <Select
              id="select_input"
              className="select_value"
              value={inputValues.isAudio}
              onChange={handleChange}
              name="isAudio"
              MenuProps={{ ...Menu }}
            >
              <StyledMenuItem value={0}>Select</StyledMenuItem>
              {audioData?.map((item, index) => {
                return (
                  <StyledMenuItem key={index} value={item.id}>
                    {item.label}
                  </StyledMenuItem>
                );
              })}
            </Select>
          </FormControl>
          {showRefAndAssetInputs ? (
            <>
              <CustomTextBox
                fieldLabel="Ref#"
                name="ref"
                value={inputValues?.ref}
                onKeyDown={handleFindAsset}
                onChange={(e) => handleChange(e, 'ref')}
                className="custom_input"
              />
              <CustomTextBox
                fieldLabel="Asset Id"
                name="asset_id"
                value={inputValues?.asset_id}
                onKeyDown={handleFindAsset}
                onChange={(e) => handleChange(e, 'asset_id')}
                className="custom_input"
              />
            </>
          ) : null}

          <Box className="button_group">
            <CustomButton
              title="Search"
              variant="contained"
              className="btn_theme"
              onClick={handleSearch}
            />
            <CustomButton
              title="Reset"
              variant="contained"
              className="btn_reset"
              onClick={handleReset}
            />
          </Box>
        </Box>
      </Box>
      <IconButton
        onClick={() => setColumnSetting(!columnSetting)}
        className="column_setting"
      >
        <MenuIcon />
      </IconButton>
      {/* {getDataGrid} */}
      <DataGrid
        config={config}
        locationId={assetListData?.getLocationtData}
        setConfig={setConfig}
        columnConfig={columnConfig}
        columnSetting={columnSetting}
        handleSidebar={() => setColumnSetting(!columnSetting)}
        filterData={inputValues}
        inputdata={inputValues}
        resetGlobalSearch={resetGlobalSearch}
        refs={ref}
        fetchAssetsInChild={fetchAssetsInChild}
        appraiserData={myProjectData?.appraisers}
        filterAssetInDataGrid={filterAssetInDataGrid}
        myProjectData={myProjectData}
        resetFilterInDataGrid={() => setFilterAssetInDataGrid(false)}
      />
    </MyDiv>
  );
}
