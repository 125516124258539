/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
// import {getFullProjectData} from './generalThunks'
// import { fetchModelListByMake } from '../../services/apiService'
// getFullProjectData

const generalSlice = createSlice({
  name: 'general',
  initialState: { name: 'Top Player Hater', assetCount: 0 },
  reducers: {
    updateMakeModelList(state, action) {
      state.makeModelList = action.payload;
    },
    updateAssetCount(state, action) {
      state.assetCount = action.payload;
    },
  },
  extraReducers: {
    // [fetchProjectData.fulfilled]
  },
});

export const { updateMakeModelList, updateAssetCount } = generalSlice.actions; // these are all the actions associated with the slice

export const getMakeModelList = () => {
  return (dispatch) => {
    //do async stuff
    dispatch(testActions.addName());
    // make a redux thunk to make this request async and then manage it accordingly
    // fetchModelListByMake
  };
};

export default generalSlice.reducer;
